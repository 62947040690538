/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from 'src/services/axios';

import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card';

interface ChecklistData {
  id: string;
  description: string;
  status: boolean;
}

function Checklist() {

  let { id } = useParams();
  let location = useLocation();

  const [checklist, setChecklist] = useState<ChecklistData[]>([]);

  const getChecklist = useCallback(() => {
    api.get(`checklistTrainee?trainee_id=${id}`).then(response => {
      setChecklist(response.data.map(item => {
        return {
          id: item.id,
          description: item.checklist.description,
          status: item.status
        }
      }))
    })
  }, [id])

  const handleClickItem = useCallback((currentItem) => {
    api.put(`checklistTrainee/${currentItem.id}`)
      .then(() => {
        toast.warn('Item atualizado com sucesso')
        setChecklist(checklist.map(item => {
          if (currentItem.id === item.id) {
            return {
              ...item,
              status: !item.status
            }
          }
          return item;
        }))
      })
      .catch(() => toast.error('Houve um erro ao tentar marcar esse item'))
  }, [checklist]);

  const initializeChecklist = useCallback(() => {
    api.post('checklistTrainee', {
      trainee_id: id
    }).then(() => getChecklist())
  }, [getChecklist, id]);

  useEffect(() => {
    getChecklist()
  }, [])

  return (
    <Container>
      <Card>
        <Card.Header as="h5">Checklist - {location?.state?.name}</Card.Header>
        {checklist?.length > 0 ? (
          <ListGroup>
            {checklist.map(item => (
              <ListGroup.Item
                key={item.id}
                active={item.status ? true : false}
                onClick={() => handleClickItem(item)}
              >
                {item.description}
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
            <Button variant="warning"
              onClick={initializeChecklist}
            >
              Inicializar checklist
            </Button>
          )}
      </Card>
    </Container>
  );
}

export default Checklist;