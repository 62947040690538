import React from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import Avatar from '../../assets/Images/AbaLogo.svg'
import { FaBuildingUser } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import './style.css'

export function UserList() {
    return (
        <>
            <Container fluid='md' >
                <Card>
                    <Card.Header as="h5" className="color-secondary border-none">
                        Listagem de usuarios
                    </Card.Header>
                    <Card.Body>
                        <Nav fill variant="tabs" defaultActiveKey="/home">
                            <Nav.Item>
                                <Nav.Link href="">Active</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="link-1">Loooonger NavLink</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="link-2">Link</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="disabled" disabled>
                                    Disabled
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Row style={{ padding: '60px 0' }} >

                            <Col sm="4">
                                <Card className="user-card ">
                                    <Card.Header className="text-center border-none bg-transparent">
                                        <img src={Avatar} alt="" className="user-card-avatar" />
                                        <h5>Dr.Estágio</h5>
                                        <h6 className="text-secondary">Email do usuario</h6>
                                    </Card.Header>

                                    <Card.Body className="rounded bg-white">
                                        <div className='d-flex flex-column' style={{ gap: '8px' }}>
                                            <h5 className='text-center'>Permissões:</h5>

                                            <div className='d-flex w-100  align-items-center color-secondary'>
                                                <FaBuildingUser style={{ fontSize: '1.4rem' }} className='mr-2' />
                                                <span style={{
                                                    padding: '4px 8px',
                                                    background: 'var(--secondary-light)',
                                                    boxShadow: '0 2px 2px #ccc',
                                                    borderLeft: '2px solid var(--secondary-color)',
                                                    borderRight: '2px solid var(--secondary-color)',
                                                    fontFamily: 'monospace',
                                                    fontWeight: 'bold',
                                                    fontSize: '18px',
                                                    color: 'var(--secondary-color)',
                                                    textAlign: 'center',
                                                    width: '100%'
                                                }}>

                                                    Empresa
                                                </span>
                                            </div>

                                        </div>
                                    </Card.Body>

                                    <Card.Footer className="border-none">
                                        <Button className="w-100" variant="danger">
                                            <i className="bi bi-trash3-fill mr-2"></i>
                                            Excluír usuario
                                        </Button>
                                    </Card.Footer>
                                </Card>
                            </Col>

                            <Col sm="4">
                                <Card className="user-card ">
                                    <Card.Header className="text-center border-none bg-transparent">
                                        <img src={Avatar} alt="" className="user-card-avatar" />
                                        <h5>Luzia josicelia Ferreira</h5>
                                        <h6 className="text-secondary">Email do usuario</h6>
                                    </Card.Header>

                                    <Card.Body className="rounded bg-white">
                                        <div className='d-flex flex-column' style={{ gap: '8px' }}>
                                            <h5 className='text-center'>Permissões:</h5>
                                            <div className='d-flex w-100 align-items-center color-primary'>
                                                <i style={{ fontSize: '1.4rem' }} className="bi bi-file-text-fill mr-2"></i>
                                                <span style={{
                                                    padding: '4px 8px',
                                                    background: 'var(--primary-light)',
                                                    boxShadow: '0 2px 2px #ccc',
                                                    borderLeft: '2px solid var(--primary-regular)',
                                                    borderRight: '2px solid var(--primary-regular)',
                                                    fontFamily: 'monospace',
                                                    fontWeight: 'bold',
                                                    fontSize: '18px',
                                                    color: 'var(--primary-regular)',
                                                    textAlign: 'center',
                                                    width: '100%'
                                                }}>

                                                    Financeiro
                                                </span>
                                            </div>

                                            <div className='d-flex w-100  align-items-center color-secondary'>
                                                <FaUsers style={{ fontSize: '1.4rem' }} className='mr-2' />
                                                <span style={{
                                                    padding: '4px 8px',
                                                    background: 'var(--secondary-light)',
                                                    boxShadow: '0 2px 2px #ccc',
                                                    borderLeft: '2px solid var(--secondary-color)',
                                                    borderRight: '2px solid var(--secondary-color)',
                                                    fontFamily: 'monospace',
                                                    fontWeight: 'bold',
                                                    fontSize: '18px',
                                                    color: 'var(--secondary-color)',
                                                    textAlign: 'center',
                                                    width: '100%'
                                                }}>

                                                    Colaborador
                                                </span>
                                            </div>


                                        </div>
                                    </Card.Body>

                                    <Card.Footer className="border-none">
                                        <Button className="w-100" variant="danger">
                                            <i className="bi bi-trash3-fill mr-2"></i>
                                            Excluír usuario
                                        </Button>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}