import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Importações do react-bootrap
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import swal from 'sweetalert'

import theme from 'src/styles/theme';

import api from 'src/services/axios';
import { useDropzone } from 'react-dropzone';

import { toast } from 'react-toastify';

const ConvitesDocumentos: React.FC = () => {
  const history = useHistory();

  const { state } = useLocation();
  console.log(state)

  const [rgFile, setRgFile] = useState<any>([]);
  const [rgVersoFile, setRgVersoFile] = useState<any>([]);
  const [cpfFile, setCpfFile] = useState<any>([]);
  const [comprovanteFile, setComprovanteFile] = useState<any>([]);
  const [rgResponsavelFile, setRgResponsavelFile] = useState<any>([]);
  const [rgVersoResponsavelFile, setRgVersoResponsavelFile] = useState<any>([]);
  const [cpfResponsavelFile, setCpfResponsavelFile] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [documentsData, setDocumentsData] = useState<any>({
    rgFile: null,
    cpfFile: null,
    comprovanteFile: null,
    rgVersoFile: null,
    rgVersoResponsavelFile: null,
    rgResponsavelFile: null,
    cpfResponsavelFile: null
  })


  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    rgFile.forEach(file => URL.revokeObjectURL(file.preview));
    cpfFile.forEach(file => URL.revokeObjectURL(file.preview));
    comprovanteFile.forEach(file => URL.revokeObjectURL(file.preview));
    rgVersoFile.forEach(file => URL.revokeObjectURL(file.preview));
    rgVersoResponsavelFile.forEach(file => URL.revokeObjectURL(file.preview));
    rgResponsavelFile.forEach(file => URL.revokeObjectURL(file.preview));
    cpfResponsavelFile.forEach(file => URL.revokeObjectURL(file.preview));
  }, [rgFile, comprovanteFile, rgResponsavelFile, cpfResponsavelFile, cpfFile, rgVersoFile, rgVersoResponsavelFile]);


  const { getRootProps: getRootPropsRg, getInputProps: getInputPropsRg } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {


      setDocumentsData({ ...documentsData, rgFile: acceptedFiles[0] })
    
      setRgFile(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });


  const { getRootProps: getRootPropsRgVerso, getInputProps: getInputPropsRgVerso } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {

      setDocumentsData({ ...documentsData, rgVersoFile: acceptedFiles[0] })

      setRgVersoFile(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const { getRootProps: getRootPropsCpfFile, getInputProps: getInputPropsCpfFile } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {

      setDocumentsData({ ...documentsData, cpfFile: acceptedFiles[0] })

    

      setCpfFile(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const { getRootProps: getRootPropsComprovanteFile, getInputProps: getInputPropsComprovanteFile } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {

      setDocumentsData({ ...documentsData, comprovanteFile: acceptedFiles[0] })
    

      setComprovanteFile(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const { getRootProps: getRootPropsCpfResponsavel, getInputProps: getInputPropsCpfResponsavel } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {

      setDocumentsData({ ...documentsData, cpfResponsavelFile: acceptedFiles[0] })

      
      setCpfResponsavelFile(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const { getRootProps: getRootPropsRgResponsavel, getInputProps: getInputPropsRgResponsavel } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {

      setDocumentsData({ ...documentsData, rgResponsavelFile: acceptedFiles[0] })


    
      setRgResponsavelFile(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const { getRootProps: getRootPropsRgVersoResponsavel, getInputProps: getInputPropsRgVersoResponsavel } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {


      setDocumentsData({ ...documentsData, rgVersoResponsavelFile: acceptedFiles[0] })

     

      setRgVersoResponsavelFile(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const handleFormData = (file) => {
    const formData = new FormData();
    formData.append('image', file);
    return formData
  }

  useEffect(() => {
    swal(
      "Agora, vamos cadastrar os seus documentos!",
      "Não abandone o processo de cadastro nessa etapa, senão todo processo anterior será perdido.",
      "info"
    )
  }, [])



  const handleSubmitDocumentsForTrainee = async data => {

    setLoading(true)

    if (rgFile.length === 0) {
      toast.error('Você esqueceu de selecionar o seu RG FRENTE')
      setLoading(false)
      return
    }

    if (rgVersoFile.length === 0) {
      setLoading(false)
      toast.error('Você esqueceu de selecionar o seu RG VERSO')
      return
    }

    if (cpfFile.length === 0) {
      setLoading(false)
      toast.error('Você esqueceu de selecionar o seu CPF')
      return
    }

    if (comprovanteFile.length === 0) {
      setLoading(false)
      toast.error('Você esqueceu de selecionar o seu comprovante de residência')
      return
    }


    const conditional = rgResponsavelFile.length > 0 && rgVersoResponsavelFile.length > 0 && cpfResponsavelFile.length > 0;

    if (state.age < 18 && !conditional) {
      setLoading(false)
      toast.error('Você esqueceu de enviar RG ou CPF do responsável')
      return
    }

    await api.post('/trainees', state.trainee).then(async response => {
       api.post(`/files?trainee_id=${response.data.id}&type_upload=trainee_document_create`, handleFormData(documentsData.rgFile))
       api.post(`/files?trainee_id=${response.data.id}&type_upload=trainee_document_create`, handleFormData(documentsData.rgVersoFile))
       api.post(`/files?trainee_id=${response.data.id}&type_upload=trainee_document_create`, handleFormData(documentsData.cpfFile))
       api.post(`/files?trainee_id=${response.data.id}&type_upload=trainee_document_create`, handleFormData(documentsData.comprovanteFile))
      
       if (state.age < 18) {
        api.post(`/files?trainee_id=${response.data.id}&type_upload=trainee_document_create`, handleFormData(documentsData.rgVersoResponsavelFile))
        api.post(`/files?trainee_id=${response.data.id}&type_upload=trainee_document_create`, handleFormData(documentsData.rgResponsavelFile))
        api.post(`/files?trainee_id=${response.data.id}&type_upload=trainee_document_create`, handleFormData(documentsData.cpfResponsavelFile))
       }
      
       history.push('/convites/sucesso')

    }).catch((error) => {
      setLoading(false)

      if (error?.response?.data?.error) {
        toast.error(error.response.data.error)
        return
      }
      toast.error("Houve um erro ao cadastrar o estagiário!");
    })
  };

  return (
    <>
      <Container className="mt-3">
        <Card>
          <Card.Header
            style={{
              color: theme.text.colors.dark,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 20px',
            }}
          >
            <span style={{ fontSize: '22px' }}>Documentos</span>
          </Card.Header>
          <Card.Body>
            <p style={{ color: '#999', fontSize: '16px', marginLeft: '10px' }}>
              Seus documentos
            </p>

            <Card className="mb-4">
              <Card.Body>
                <div  {...getRootPropsRg({ className: 'dropzone' })} className="d-flex justify-content-between  align-items-center">
                  <input {...getInputPropsRg()} />
                  <span>Clique aqui para adicionar/alterar a <b>FRENTE DO SEU RG</b></span>
                  <img src={rgFile[0]?.preview} className="img-thumbnail" style={{
                    display: 'inline-flex',
                    borderRadius: 2,
                    border: '1px solid #eaeaea',
                    marginBottom: 8,
                    marginRight: 8,
                    width: 100,
                    height: 100,
                    padding: 4,
                    boxSizing: 'border-box'
                  }}
                    alt="Documento RG"
                    hidden={!(rgFile[0]?.preview)}
                  />
                </div>
              </Card.Body>
            </Card>


            <Card className="mb-4">
              <Card.Body>
                <div  {...getRootPropsRgVerso({ className: 'dropzone' })} className="d-flex justify-content-between  align-items-center">
                  <input {...getInputPropsRgVerso()} />
                  <span>Clique aqui para adicionar/alterar o <b>VERSO DO SEU RG</b></span>
                  <img src={rgVersoFile[0]?.preview} className="img-thumbnail" style={{
                    display: 'inline-flex',
                    borderRadius: 2,
                    border: '1px solid #eaeaea',
                    marginBottom: 8,
                    marginRight: 8,
                    width: 100,
                    height: 100,
                    padding: 4,
                    boxSizing: 'border-box'
                  }}
                    alt="Documento RG VERSO"
                    hidden={!(rgVersoFile[0]?.preview)}
                  />
                </div>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <div  {...getRootPropsCpfFile({ className: 'dropzone' })} className="d-flex justify-content-between  align-items-center">
                  <input {...getInputPropsCpfFile()} />
                  <span>Clique aqui para adicionar/alterar o seu <b>CPF</b></span>
                  <img src={cpfFile[0]?.preview} className="img-thumbnail" style={{
                    display: 'inline-flex',
                    borderRadius: 2,
                    border: '1px solid #eaeaea',
                    marginBottom: 8,
                    marginRight: 8,
                    width: 100,
                    height: 100,
                    padding: 4,
                    boxSizing: 'border-box'
                  }}
                    alt="Documento RG"
                    hidden={!(cpfFile[0]?.preview)}
                  />
                </div>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <div  {...getRootPropsComprovanteFile({ className: 'dropzone' })} className="d-flex justify-content-between  align-items-center">
                  <input {...getInputPropsComprovanteFile()} />
                  <span>Clique aqui para adicionar/alterar o seu <b>Comprovante de residência</b></span>
                  <img src={comprovanteFile[0]?.preview} className="img-thumbnail" style={{
                    display: 'inline-flex',
                    borderRadius: 2,
                    border: '1px solid #eaeaea',
                    marginBottom: 8,
                    marginRight: 8,
                    width: 100,
                    height: 100,
                    padding: 4,
                    boxSizing: 'border-box'
                  }}
                    alt="Documento Comprovante"
                    hidden={!(comprovanteFile[0]?.preview)}
                  />
                </div>
              </Card.Body>
            </Card>

            <div hidden={state.age > 18}>
              <p style={{ color: '#999', fontSize: '16px', marginLeft: '10px' }}>
                Documentos do responsável - Apenas para menores de 18 anos
              </p>

              <Card className="mb-4">
                <Card.Body>
                  <div  {...getRootPropsCpfResponsavel({ className: 'dropzone' })} className="d-flex justify-content-between  align-items-center">
                    <input {...getInputPropsCpfResponsavel()} />
                    <span>Clique aqui para adicionar/alterar a <b> FRENTE do RG do responsável</b></span>
                    <img src={cpfResponsavelFile[0]?.preview} className="img-thumbnail" style={{
                      display: 'inline-flex',
                      borderRadius: 2,
                      border: '1px solid #eaeaea',
                      marginBottom: 8,
                      marginRight: 8,
                      width: 100,
                      height: 100,
                      padding: 4,
                      boxSizing: 'border-box'
                    }}
                      alt="Documento RG"
                      hidden={!(cpfResponsavelFile[0]?.preview)}
                    />
                  </div>
                </Card.Body>
              </Card>


              <Card className="mb-4">
                <Card.Body>
                  <div  {...getRootPropsRgVersoResponsavel({ className: 'dropzone' })} className="d-flex justify-content-between  align-items-center">
                    <input {...getInputPropsRgVersoResponsavel()} />
                    <span>Clique aqui para adicionar/alterar o <b>VERSO do RG do responsável</b></span>
                    <img src={rgVersoResponsavelFile[0]?.preview} className="img-thumbnail" style={{
                      display: 'inline-flex',
                      borderRadius: 2,
                      border: '1px solid #eaeaea',
                      marginBottom: 8,
                      marginRight: 8,
                      width: 100,
                      height: 100,
                      padding: 4,
                      boxSizing: 'border-box'
                    }}
                      alt="Documento RG"
                      hidden={!(rgVersoResponsavelFile[0]?.preview)}
                    />
                  </div>
                </Card.Body>
              </Card>

              <Card className="mb-4">
                <Card.Body>
                  <div  {...getRootPropsRgResponsavel({ className: 'dropzone' })} className="d-flex justify-content-between  align-items-center">
                    <input {...getInputPropsRgResponsavel()} />
                    <span>Clique aqui para adicionar/alterar o <b>CPF do responsável</b></span>
                    <img src={rgResponsavelFile[0]?.preview}
                      className="img-thumbnail" style={{
                        display: 'inline-flex',
                        borderRadius: 2,
                        border: '1px solid #eaeaea',
                        marginBottom: 8,
                        marginRight: 8,
                        width: 100,
                        height: 100,
                        padding: 4,
                        boxSizing: 'border-box'
                      }}
                      alt="Documento RG"
                      hidden={!(rgResponsavelFile[0]?.preview)}
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Card.Body>

          <Card.Footer>
            {!loading ? (
              <Form.Row>
                <Button
                  className="mr-3"
                  type="submit"
                  variant="success"
                  disabled={loading}
                  onClick={handleSubmitDocumentsForTrainee}
                >
                  Finalizar cadastro
                </Button>
              </Form.Row>
            ) : 'Finalizando'}


          </Card.Footer>

        </Card>
      </Container>

    </>
  );
}

export default ConvitesDocumentos;
