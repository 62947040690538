import React from 'react'
import { useRef, ChangeEvent, useState } from "react";
import SendImage from '../../assets/Enviar foto 1.svg';

type UploadImageFieldProps = {
  handleChangeSelectPhoto(file: File): void
}
export const UploadImageField: React.FC<UploadImageFieldProps> = ({ handleChangeSelectPhoto }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
      handleChangeSelectPhoto(file)
    }
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  return (
    <div
      onClick={handleClick}
      style={{
        cursor:'pointer',
        borderRadius:'50px',
        borderColor:'var(--secondary-color)',
        padding:'8px',
        overflow:'hidden',
        position:'relative'}}
    >
      <div style={{justifyContent:'center',alignItems:'center',height:'100%'}}>
        {selectedFile ? (
          <img style={{border:'dashed 1px var(--gray-400)', height:'220px', width:'220px',borderRadius:'100%'}} src={URL.createObjectURL(selectedFile)} alt="Imagem do usuário" />
        ) : (
          <img style={{border:'dashed 1px var(--gray-400)', height:'220px', width:'220px',borderRadius:'100%'}} src={SendImage} alt="Perfil" />
        )}
      </div>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        className='d-none'
        onChange={handleFileChange}
      />
    </div>
  );
};

