import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

// Importações do bootstrap
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Input from '../../../components/Input';

import schema from './schema';
import api from 'src/services/axios';

import { toast } from 'react-toastify';

const CoursesForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();

  const history = useHistory();
  const { handleSubmit, control, setValue, errors } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    }
  });


  useEffect(() => {
    if (params.id) {
      api.get(`/courses/${params.id}`).then(response => {
        setValue('name', response.data?.name)
      })
    }
  }, [params.id, setValue]);

  const onSubmit = data => {
    setLoading(true)
    
    const company = {
      name: data.name
    };


    if (params.id) {
      api.put(`/courses/${params.id}`, company).then(() => {
        history.goBack();
        toast.success("Atualizado com sucesso!");
      }).catch(() => {
        toast.error("Houve um erro ao atulizar o curso!");
      })
        .finally(() => setLoading(false))
    } else {
      api.post('/courses', company).then(() => {
        history.goBack();
        toast.success("Cadastrado com sucesso!");
      }).catch(() => {
        toast.error("Houve um erro ao cadastrar o curso!");
      })
        .finally(() => setLoading(false))
    }

  };

  return (
    <Container>
      <Card style={{ boxShadow: 'var(--card-bs)', background: 'var(--gray-100)' }}>
        <Card.Header as='h5' style={{ background: 'none', border: 'none', color: 'var(--secondary-color)' }}>
          Nova opção de curso
        </Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body style={{ background: 'white', borderRadius: '10px', margin: '0.5rem 1rem' }}>
            <Form.Row>

              <Controller
                as={Input}
                control={control}
                name="name"
                label="Nome do curso"
                errorMessage={errors.name?.message}
                md="5"
                required
              />
            </Form.Row>
          </Card.Body>
          <Card.Footer className='bg-white'>
            <Form.Row>
              <Button className="mr-3 button-primary" disabled={loading} type="submit">{loading ? 'Carregando...' : 'Salvar'}</Button>
              <Button onClick={() => history.goBack()} className='button-secondary-outline'>Cancelar</Button>
            </Form.Row>
          </Card.Footer>
        </Form>
      </Card>
    </Container>
  );
}

export default CoursesForm;