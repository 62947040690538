import React, { forwardRef } from 'react';
import { Map } from 'immutable';
import MaskedInput from 'react-text-mask';
import Label from '../Label';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

type MasksType = 'cep' | 'cpf' | 'cel' | 'tel' | 'cnpj' | 'date';

export interface InputMaskProps {
  name: string;
  mask: MasksType;
  label?: string;
  md?: string;
  errorMessage?: string;
  defaultValue: string;
  required?: boolean;
  className?: string;
}

type Ref = HTMLInputElement;

const masks = Map({
  cpf: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  cnpj: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  date: [
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  cel: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  tel: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
});

const InputMask = forwardRef<Ref, InputMaskProps>(
  ({
    name,
    mask,
    label = '',
    md = '4',
    errorMessage = '',
    defaultValue,
    required = false,
    className,
    ...rest
  }) => {

    return (
      <Form.Group as={Col} md={md} controlId={`form${label}`}>
        <Label text={label} required={required} />
        <MaskedInput
          mask={masks.get(mask)!}
          defaultValue={defaultValue}
          name={name}
          guide={false}
          className={className}  
          {...rest}
          render={(ref, props) => (
            <Form.Control
              ref={ref}
              isInvalid={errorMessage ?? 'error'}
              {...props}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
    );
  },
);

export default React.memo(InputMask);
