import React, { useState, useEffect, useRef } from 'react';
import { FaGift } from 'react-icons/fa';
import '../css/fraseDoDia.css';

const frases = [
    "Acredite em si mesmo! (1)",
    "Trabalho duro supera o talento! (2)",
    "O sucesso é a soma de pequenos esforços repetidos dia após dia. (3)",
    "A persistência é o caminho do êxito. (4)",
    "Seu futuro é criado pelo que você faz hoje, não amanhã. (5)",
    "Não desista, o começo é sempre o mais difícil. (6)" ,
    "A única maneira de fazer um excelente trabalho é amar o que você faz. (7)",
    "O sucesso é a soma de pequenos esforços diários. (8)",
    "Cada conquista começa com a decisão de tentar. (9)",
    "Acredite que você pode, e você já está no meio do caminho. (10)",
    "Grandes coisas nunca vêm de zonas de conforto. (11)",
    "A ação é a chave fundamental para todo sucesso. (12)",
    "Não espere por oportunidades, crie-as. (13)",
    "O fracasso é o tempero que dá sabor ao sucesso. (14)",
    "A única maneira de alcançar o impossível é acreditar que é possível. (15)",
    "Nunca é tarde demais para ser o que você poderia ter sido. (16)",
    "Sucesso não é a chave para a felicidade. Felicidade é a chave para o sucesso. (17)",
    "A perseverança é a mãe da boa sorte. (18)",
    "Sua única limitação é a sua imaginação. (19)",
    "Dificuldades muitas vezes preparam pessoas comuns para destinos extraordinários. (20)",
    "Seja a mudança que você deseja ver no mundo. (21)",
    "Cada erro ensina algo novo. (22)",
    "A chave para o sucesso é focar nas metas, não nos obstáculos. (23)",
    "Faça hoje o que outros não querem, e amanhã você viverá o que outros não podem. (24)",
    "O caminho para o sucesso está sempre em construção. (25)",
    "O que você faz hoje pode melhorar todos os seus amanhãs. (26)",
    "A coragem não é a ausência de medo, mas a vitória sobre ele. (27)",
    "Não sonhe com o sucesso, trabalhe para ele. (28)",
    "A jornada de mil milhas começa com um único passo. (29)",
    "Tudo parece impossível até que seja feito. (30)",
    "A maior glória em viver não está em nunca cair, mas em nos levantarmos cada vez que caímos. (31)",
    "O sucesso não é o final, o fracasso não é fatal: é a coragem de continuar que conta. (32)",
    "Transforme seus sonhos em planos e seus planos em realidade. (33)",
    "O melhor modo de prever o futuro é criá-lo. (34)",
    "Sucesso é a habilidade de ir de fracasso em fracasso sem perder o entusiasmo. (35)",
    "O único lugar onde o sucesso vem antes do trabalho é no dicionário. (36)",
    "Nunca deixe de acreditar que a sua próxima tentativa pode ser a vencedora. (37)",

];


const FraseDoDia: React.FC = () => {
    const [showMessage, setShowMessage] = useState(false);
    const [currentMessage, setCurrentMessage] = useState('');
    const [isPaused, setIsPaused] = useState(false);
    const timeoutRef = useRef<number | undefined>(undefined);

    useEffect(() => {
        const today = new Date().toDateString();
        const lastShownDate = localStorage.getItem('lastShownDate');
        const lastMessage = localStorage.getItem('lastMessage');

        if (lastShownDate !== today) {
            let availableMessages = frases.filter(f => f !== lastMessage);
            if (availableMessages.length === 0) {
                availableMessages = frases;
            }
            const randomMessage = availableMessages[Math.floor(Math.random() * availableMessages.length)];
            setCurrentMessage(randomMessage);
            localStorage.setItem('lastShownDate', today);
            localStorage.setItem('lastMessage', randomMessage);
        } else if (lastMessage) {
            setCurrentMessage(lastMessage);
        }
    }, []);

    const showMessageHandler = () => {
        if (showMessage) return;
        setShowMessage(true);

        timeoutRef.current = window.setTimeout(() => {
            setShowMessage(false);
        }, 6000);
    };

    const pauseTimer = () => {
        setIsPaused(true);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    const resumeTimer = () => {
        setIsPaused(false);
        timeoutRef.current = window.setTimeout(() => {
            setShowMessage(false);
        }, 6000);
    };

    return (
        <>
            <div className="present-icon" onClick={showMessageHandler}>
                <FaGift size={30} color="white" />
            </div>
            {showMessage && (
                <div 
                    className="daily-message" 
                    onMouseEnter={pauseTimer}
                    onMouseLeave={resumeTimer}
                >
                    {currentMessage}
                </div>
            )}
        </>
    );
};

export default FraseDoDia;
