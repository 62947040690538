import * as yup from 'yup';

const schema = yup.object().shape({
  reason_shutdown: yup
    .object()
    .required('Campo obrigatório')
    .nullable(true),
  date_shutdown: yup
    .date()
    .required('Campo obrigatório')
});

export default schema;