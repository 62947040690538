export default {
  semantic: {
    primary: '#007bff',
    secondary: '#ced4da',
    success: '#28a745',
    info: '#17a2b8',
    warning: '#ffc107',
    danger: '#dc3545',
    light: '#f8f9fa',
    dark: '#343a40',
  },
  text: {
    colors: {
      primary: '#007bff',
      secondary: '#6c757d',
      success: '#28a745',
      info: '#17a2b8',
      warning: '#ffc107',
      danger: '#dc3545',
      light: '#f8f9fa',
      dark: '#343a40',
      body: '#212529',
      muted: '#6c757d',
    },
    hover: {
      primary: '#0056b3',
      secondary: '#494f54',
      success: '#19692c',
      info: '#0f6674',
      warning: '#ba8b00',
      danger: '#a71d2a',
      light: '#cbd3da',
      dark: '#121416',
    },
  },
  focus: {
    danger: '0 0 0 0.2rem rgba(220, 53, 69, 0.25)',
    primary:
      'inset 0 1px 2px rgba(203,203,210,.2), 0 0 4px rgba(121,88,159,.3)',
  },
  estagio_fast: {
    primary: '#2C569A',
    secondary: '#F2D627',
  },

  colors: {
    primary: '#FF8700',
    primaryDark: '#FF7A00',
    secondary: '#FF500F',
    grey: '#8C91A5',
    greyLowerOpacity: '#abaebf',
    lightGrey: '#F5F6FA',
    darkGrey: '#6C757D',
    success: '#20C05C',
    error: '#FD4F48',
    label: '#fff',
    background: '#fff',
    yellow: '#FFED00',
  },
  fontSizes: {
    default: '1.4rem',
    large: '1.6rem',
    small: '1.2rem',
    tiny: '1rem',
  },
  spacing: {
    default: '1.6rem',
    vertical: '1.6rem 0',
    horizontal: '0 1.6rem',
    large: '2.4rem',
  },
  transition: {
    default: '180ms ease-in-out',
  },
  radii: {
    default: '0.8rem',
    small: '0.4rem',
    smallTop: '0.4rem 0.4rem 0 0',
    smallBottom: '0 0 0.4rem 0.4rem',
    tiny: '0.2rem',
  },
  shadows: {
    default: '0 0.5rem 2rem rgba(0, 0, 0, 0.08)',
    flat: '0 0.2rem 0.2rem rgba(0, 0, 0, 0.08)',
  },
};
