import React from "react";
import { Container, Card, Button, Row, Col, Accordion, ListGroup } from "react-bootstrap";
import { FaFilePdf, FaClipboardCheck, FaFileContract, FaUserMinus, FaBuilding, FaCalendarCheck, FaChartBar } from "react-icons/fa";

import Vaga from '../../../assets/solicitacoes/orientacao_processo_seletivo_3.0.pdf';
import Contrato from '../../../assets/solicitacoes/carta_de_orientacao.pdf';
import Cartao from '../../../assets/solicitacoes/cartao-pre-cadastro.jpg';
import ProcessoSeletivo from '../../../assets/solicitacoes/Slide-para-o-processo-seletivo.pptx';
import Cartilha from '../../../assets/solicitacoes/Cartilha-financeira.pdf'

import './style.css';

const buttonInfos = [
    /*{
        label: "Solicitar Vaga",
        description: (
            <>
                <p>Este campo permite que sua empresa manifeste interesse em candidatos para vagas de estágio disponíveis, contribuindo para o fortalecimento das equipes e complementando os currículos dos candidatos.</p>
                <p>Por favor, preencha as informações relevantes ao perfil desejado. Certifique-se de revisar todos os dados antes de enviar a solicitação. Nossa equipe entrará em contato para agendar entrevistas com os candidatos que melhor se alinhem às suas necessidades.</p>
                <p><strong>Fique atento às Regras do Processo Seletivo. Baixe o PDF com as orientações completas.</strong></p>
            </>
        ),
        variant: "primary",
        link: "https://form.jotform.com/drestagioof/solicitacao-de-vagas",
        pdfLink: Vaga,
        icon: <FaClipboardCheck />
    },
    */
    {
        label: "Solicitar contrato de estágio",
        description: (
            <>
                <p>Utilize este campo para que sua empresa solicite contratos de estágio, assegurando a formalização e regularização dos estagiários conforme as normas vigentes.</p>
                <p>Verifique todas as informações antes de enviar a solicitação. Nossa equipe entrará em contato para concluir o processo e fornecer o suporte necessário.</p>
                <p><strong>Fique atento às regras de contratação. Baixe o PDF com as orientações completas.</strong></p>
            </>
        ),
        variant: "success",
        link: "https://form.jotform.com/241765434055659",
        pdfLink: Contrato,
        icon: <FaFileContract />
    },
    {
        label: "Solicitar desligamento de estágio",
        description: (
            <>
                <p>Utilize este campo para solicitar o desligamento formal de estagiários da sua empresa. Por favor, forneça as informações necessárias, como o nome do estagiário, data prevista para o desligamento, e o motivo do encerramento do estágio.</p>
                <p>Certifique-se de revisar todos os dados antes de enviar a solicitação. Nossa equipe processará o pedido e entrará em contato para confirmar os próximos passos.</p>
            </>
        ),
        variant: "danger",
        link: "https://form.jotform.com/241766196179672",
        icon: <FaUserMinus />
    },
    {
        label: "Inclusão de CNPJ",
        description: (
            <>
                <p>Insira o CNPJ da sua empresa neste campo. O CNPJ é um identificador essencial para formalizar contratos, solicitações e outras operações relacionadas à sua empresa.</p>
                <p>Verifique se o número inserido está correto e atualizado antes de enviar.</p>
            </>
        ),
        variant: "warning",
        link: "https://form.jotform.com/233025932642654",
        icon: <FaBuilding />
    },
];

export const Solicitations: React.FC = () => {
    return (
        <Container className="solicitations-home shadow-sm" style={{ minHeight: '100vh', background: '#FFF' }}>
            <Row className="w-100 mt-4">
                <Col sm={12} className="text-center mb-4">
                    <h1>Bem-vindo ao Estagius</h1>
                    <p>Gerencie todas as solicitações e informações de estagiários diretamente pelo sistema.</p>
                </Col>

                <Col sm={12}>
                    <Accordion>
                        {buttonInfos.map((button, index) => (
                            <Card key={index} className="mb-3" style={{ borderRadius: '10px' }}>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={`${index}`}
                                    className={`d-flex align-items-center justify-content-between border-${button.variant}`}
                                    style={{ borderRadius: '10px', cursor: 'pointer', fontSize: '1.2rem', color: button.variant, borderWidth: '2px', borderStyle: 'solid', background: 'white' }}
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="mr-3">{button.icon}</div>
                                        <div>{button.label}</div>
                                    </div>
                                    <div>
                                        <Button
                                            variant={button.variant}
                                            href={button.link}
                                            target="_blank"
                                            className="mr-2"
                                            style={{ fontSize: '1rem', padding: '6px 12px', borderRadius: '5px', color: 'white', border: 'none' }}
                                        >
                                            Acessar
                                        </Button>
                                        {button.pdfLink && (
                                            <Button
                                                variant="dark"
                                                href={button.pdfLink}
                                                target="_blank"
                                                className="ml-2"
                                                style={{ fontSize: '1rem', padding: '6px 12px', borderRadius: '5px' }}
                                            >
                                                <FaFilePdf />
                                            </Button>
                                        )}
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={`${index}`}>
                                    <Card.Body>
                                        {button.description}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>
                </Col>
            </Row>

            {/* Seção de Material de Apoio */}
            <Row className="mt-5">
                <Col sm={12} className="text-center">
                    <h2>Material de Apoio</h2>
                </Col>
                <Col sm={12}>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <h5>Cartão Pré-cadastro</h5>
                            <p>Deve ser entregue ao candidato que for selecionado para a vaga de estágio. Com este folder, ele vai entrar em contato com a nossa equipe.</p>
                            <div className="d-flex justify-content-between">
                                <Button style={{ width: '140px' }} variant="primary" href={Cartao} target="_blank">
                                    Ver Cartão
                                </Button>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h5>Slide para o processo seletivo</h5>
                            <p>Pode ser usado no momento da sua seleção de candidatos.</p>
                            <div className="d-flex justify-content-between">
                                <Button style={{ width: '140px' }} variant="primary" href={ProcessoSeletivo} target="_blank">
                                    Ver Slide
                                </Button>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h5>Cartilha Financeira</h5>
                            <p>Tire suas principais dúvidas sobre questões financeiras.</p>
                            <div className="d-flex justify-content-between">
                                <Button style={{ width: '140px' }} variant="primary" href={Cartilha} target="_blank">
                                    Ver Cartilha
                                </Button>
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>

            {/* Seção de Acompanhamento de Presenças */}
            <Row className="mt-5">
                <Col sm={12} className="text-center">
                    <h2>Acompanhe as Presenças dos Estagiários</h2>
                    <p>O Estagius oferece uma funcionalidade completa para que sua empresa acompanhe de perto as presenças dos estagiários. Através do sistema, você pode visualizar o histórico de presenças, verificar datas específicas e conferir a quantidade de presenças e faltas de cada estagiário. Isso facilita a gestão e garante que todos os registros estejam atualizados e acessíveis a qualquer momento.</p>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col sm={6}>
                    <Card className="text-center hover-card h-100" style={{ borderRadius: '10px', padding: '30px', border: '2px solid #007bff', background: 'white', color: 'black', transition: 'transform 0.3s' }}>
                        <Card.Body style={{ borderRadius: '10px' }}>
                            <FaCalendarCheck size={70} className="mb-3" style={{ opacity: 0.9, color: '#007bff' }} />
                            <h5 className="mb-4">Verifique as presenças detalhadas dos estagiários.</h5>
                            <Button variant="primary" href="/relatorios/empresas/presencas" style={{ fontSize: '1.2rem', padding: '10px 20px', borderRadius: '5px' }}>
                                Presenças
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={6}>
                    <Card className="text-center hover-card h-100" style={{ borderRadius: '10px', padding: '30px', border: '2px solid #28a745', background: 'white', color: 'black', transition: 'transform 0.3s' }}>
                        <Card.Body style={{ borderRadius: '10px' }}>
                            <FaChartBar size={70} className="mb-3" style={{ opacity: 0.9, color: '#28a745' }} />
                            <h5 className="mb-4">Acompanhe a contagem de presenças e faltas.</h5>
                            <Button variant="success" href="/relatorios/empresas/contagem" style={{ fontSize: '1.2rem', padding: '10px 20px', borderRadius: '5px' }}>
                                Contagem de presenças
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};
