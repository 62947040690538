import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import Navbar from 'react-bootstrap/Navbar';

import "react-datepicker/dist/react-datepicker.css";
import Nav from 'react-bootstrap/Nav'
import { signOut } from 'src/store/modules/auth/actions';
import { NavDropdown } from 'react-bootstrap';
import Logo from '../../../assets/Images/logo.png'

import '../default/style.css'

function CompanyLayout({ children }) {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const handleSignOut = () => {
    dispatch(signOut(true));
  }
  const location = useLocation();
  return (
    <>
      <Navbar className="navbar-custom" collapseOnSelect expand="lg" variant="dark">
        <Nav style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Navbar.Brand as={Link} to="/">
            <img alt='Estagius' src={Logo} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className='justify-content-center' id="responsive-navbar-nav">
            <Nav.Link className={location.pathname === '/relatorios/empresas/presencas' ? 'active' : 'not-active'} to="/relatorios/empresas/presencas" as={Link}>Presenças</Nav.Link>
            <Nav.Link className={location.pathname === '/relatorios/empresas/contagem' ? 'active' : 'not-active'} to="/relatorios/empresas/contagem" as={Link}>Contagem de presenças</Nav.Link>
            <Nav.Link className={location.pathname === '/solicitacoes' ? 'active' : 'not-active'} to="/solicitacoes" as={Link}>Solicitações</Nav.Link>
          </Navbar.Collapse>
          <NavDropdown title={profile.name} id="collasible-nav-dropdown">
            <NavDropdown.Item className='text-white' onClick={handleSignOut}>Sair</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar >
      {children}
    </>
  );
}

export default CompanyLayout;