import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Label from '../Label';
import AsyncSelect from 'react-select/async';

import '../Select/styles.css';

interface SelectProps {
  label: string;
  name: string;
  errorMessage?: string;
  md?: string;
  loadOptions: (inputValue: string) => void;
  error?: boolean;
  required?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
}

const Select: React.FC<SelectProps> = ({
  md = 4,
  label,
  errorMessage = '',
  loadOptions,
  required = false,
  isClearable = true,
  isMulti = false,
  ...rest
}) => {
  const customStyles = {
    control: (base: any) => {
      const custom: any = {};
      if (errorMessage) {
        custom.borderColor = 'red';
      }
      return {
        ...base,
        ...custom,
        '&:focus': { borderColor: 'var(--secondary-color)', border: 'none' },
      };
    },
  }
 
  return (
    <Form.Group as={Col} md={md} controlId={`form${label}`}>
      <Label text={label} required={required} />

      <AsyncSelect
        styles={customStyles}
        placeholder="Selecione"
        theme={theme => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary: '#2C569A',
          },
        })}
    
        
        loadOptions={loadOptions}
        isClearable={isClearable}

        isMulti={isMulti}
        {...rest}
      />
      {errorMessage && <div className="invalid-custom">{errorMessage}</div>}
    </Form.Group>
  );
};

export default Select;
