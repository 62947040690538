import React, { useEffect, useState } from 'react';
import './effects.css';

interface AnimationData {
    delay: number;
    duration: number;
}

export const Bubbles: React.FC = () => {
    const [animationData, setAnimationData] = useState<AnimationData[]>([]);

    useEffect(() => {
        const generateRandomData = () => {
            const animations: AnimationData[] = [];

            for (let i = 1; i <=50; i++) {
                const delay = Math.floor(Math.random() * 16) + 1; // Atraso aleatório entre 1 e 16 segundos
                const duration = Math.floor(Math.random() * 10) + 3; // Duração aleatória entre 3 e 12 segundos
                animations.push({ delay, duration });
            }

            setAnimationData(animations);
        };

        generateRandomData();
    }, []);

    return (
        <div className='bubbles'>
            {animationData.map((data, index) => (
               <span
           
               key={index}
               className={`anim-delay-${data.delay}`}
               style={{ animationDuration: `${data.duration}s` }}
              ></span>
                    
            ))}
        </div>
    );
};

