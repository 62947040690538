import React, { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import Label from '../Label';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';



const DateInput = ({ label, required, errorMessage, onChange, md = '4', ...rest }) => {
  registerLocale('pt-BR', ptBR);

  const handleChange = useCallback(
    (date: Date) => {
      onChange(date);
    },
    [onChange],
  );


  return (
    <Form.Group
      as={Col}
      md={md}
      controlId={`form${label}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Label text={label} required={required} />

      <DatePicker
        dateFormat="dd/MM/yyyy"
        autoComplete="off"
        showPopperArrow={false}
        locale="pt-BR"
        customInput={<Form.Control isInvalid={!!errorMessage ?? 'error'} />}
        valueName="selected"
        selected={rest.value}
        onChange={handleChange}
        {...rest}
      />

      {errorMessage && <div className="invalid">{errorMessage}</div>}
    </Form.Group>
  );
};

export default React.memo(DateInput);
