import React from "react";
import { useLocation } from "react-router-dom";
import './style.css';
import Logo from '../../assets/Images/logo.png';

export const Footer: React.FC = () => {
    const location = useLocation();

    // Lista de rotas onde o footer não deve aparecer
    const hiddenFooterRoutes = ["/",'/parceiros'];

    // Verifica se a rota atual está na lista
    const shouldHideFooter = hiddenFooterRoutes.includes(location.pathname);

    if (shouldHideFooter) {
        return null;
    }

    return (
        <>
            <footer>
                <img src={Logo} alt="Logo" width='150px' />
            </footer>
            <div className="footer-message">
            <p>Todos os direitos reservados &copy; 2019-{new Date().getFullYear()}</p>
            </div>
        </>

    );
};


