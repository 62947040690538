import React, { useEffect, useState } from 'react';
import '../css/PresentationScreen.css';
import { Bubbles } from '../../../components/BubblesEffect/Bubbles';
import { Container } from 'react-bootstrap';

interface PresentationScreenProps {
    onClose: () => void;
}

const PresentationScreen: React.FC<PresentationScreenProps> = ({ onClose }) => {
    const [showContent, setShowContent] = useState<boolean>(false);
    const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowContent(true);
        }, 5000); // Mostra o conteúdo após 7 segundos (tempo total da animação)

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        if (doNotShowAgain) {
            localStorage.setItem('presentationSeen', 'false');
        }
        onClose();
    };

    return (
        <div className="presentation-screen-container">
            <Bubbles />
            <Container>
                <h1 className="text-center color-primary mb-4">Bem-vindo ao</h1>
                <div className="logo-animation-container">
                    <div className="logo-text">
                        <span className="animate-letter-e">E</span>
                        <span className="animate-letter-s">S</span>
                        <span className="animate-letter-t">T</span>
                        <span className="animate-letter-a">A</span>
                        <span className="animate-letter-g">G</span>
                        <span className="star-container">
                            <span className="animate-letter-i">I</span>
                            <div className="star shineOne"></div>
                            <div className="star shineTwo"></div>
                        </span>
                        <span className="animate-letter-u">U</span>
                        <span className="animate-letter-s2">S</span>
                    </div>
                </div>

                <div className="presentation-content-container">
                    {/* Conteúdo principal aqui */}

                    <p>
                        O Estagius é uma plataforma completa e intuitiva projetada para ajudar a gerenciar todos os aspectos do programa de estágio.
                        Controle cadastros, organize estagiários, gerencie a parte financeira, e muito mais.
                    </p>

                    <div className="presentation-checkbox-container">
                        <input
                            type="checkbox"
                            id="doNotShowAgain"
                            checked={doNotShowAgain}
                            onChange={(e) => setDoNotShowAgain(e.target.checked)}
                        />
                        <label htmlFor="doNotShowAgain">Não mostrar essa tela novamente</label>
                    </div>

                    <button className="presentation-close-button" onClick={handleClose}>Continuar</button>
                </div>


            </Container>
        </div>
    );
};

export default PresentationScreen;
