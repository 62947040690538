import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Importações do bootstrap
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

// Meus componentes
import ComplexTable from '../../../components/ComplexTable';

//services
import api from '../../../services/axios';
import { parseISO, format } from 'date-fns';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Icon from '../../../components/icons';

const ContactList: React.FC = () => {
  const history = useHistory();

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    api.get('contacts')
      .then((response) => {
        setContacts(response.data.map((item) => ({
          ...item,
          date_birth: item.date_birth ? format(parseISO(item.date_birth.substring(0,10)), 'dd-MM-yyyy') : 'Não informado'
        })));
      })
  }, []);

  return (
    <Container fluid>
      <Card style={{boxShadow:'var(--card-bs)'}}>
        <Card.Header as="h5" className='d-flex justify-content-between align-items-center color-secondary'>
          <div>
          <i className="bi bi-stack mr-2"></i>
          Contatos
          </div>
          <Button
            variant="primary"
            onClick={() => history.push('/contatos/novo')}
            className="button-primary"
          >
                  <i className="bi bi-plus-lg mr-2"></i>
            Novo contato
          </Button>
         
        </Card.Header>
        <Card.Body>
          <ComplexTable
            data={contacts}
            columns={[
              {
                dataField: 'company_name',
                text: 'Empresa',
                sort: false,
                filter: true,
              },
              {
                dataField: 'sector',
                text: 'Setor',
                sort: false,
                filter: true,
              },
              {
                dataField: 'name',
                text: 'Nome',
                sort: false,
                filter: true,
              },
              {
                dataField: 'main_contact',
                text: '1º Contato',
                sort: false,
                filter: true,
              },
              {
                dataField: 'secondary_contact',
                text: '2º Contato',
                sort: false,
                filter: true,
              },
              {
                dataField: 'email',
                text: 'Email',
                sort: false,
                filter: true,
              },
              {
                dataField: 'date_birth',
                text: 'Aniversário',
                sort: false,
                filter: true,
              },
              {
                dataField: 'id',
                text: 'Ações',
                sort: false,
                headerStyle: {
                  width: '15%',
                },
                formatter: (_, row) => (
                  <>
                  
                    <OverlayTrigger
                      key="edit"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          Editar Empresa
                        </Tooltip>
                      }
                    >
                      <Icon icon="FaEdit" className="color-secondary interaction" size={25} onClick={() => history.push(`contatos/${row.id}`)} />
                    </OverlayTrigger>                
                  </>
                )
              },
            ]}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ContactList;
