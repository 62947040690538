import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';

import { signOut } from 'src/store/modules/auth/actions';
import Logo from '../../../assets/Images/logo.png';
import PresentationScreen from 'src/screens/Dashboard/components/PresentationScreen';


import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import './style.css';

function DefaultLayout({ children }) {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const location = useLocation();

  const [showPresentation, setShowPresentation] = useState(!localStorage.getItem('presentationSeen'));

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const rotaCadastro = [
    '/empresas', '/estagiarios', '/instituicao_educacao', '/grupos', '/contatos'
  ].includes(location.pathname);

  const rotaContrato = [
    '/contratos', '/relatorios/contratos_ativos', '/relatorios/contratos_desligados', '/relatorios/contratos_expirados',
  ].includes(location.pathname);

  const rotaPedagogico = [
    '/relatorios/presencas', '/relatorios/presencas/contagem',
  ].includes(location.pathname);

  const handleLogoClick = () => {
    setShowPresentation(true);
    // Não alterar o localStorage quando o usuário clicar no logo.
  };

  return (
    <>
      <Navbar className="navbar-custom" collapseOnSelect expand="lg" variant="dark">
        <Nav style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Navbar.Brand as={Link} to="/" onClick={handleLogoClick}>
            <img alt='Estagius' src={Logo} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className='justify-content-center' id="responsive-navbar-nav">
            <Nav.Link as={Link} to="/dashboard" active={location.pathname === '/dashboard'}>Painel de controle</Nav.Link>
            <Nav.Link as={Link} to="/agenda" active={location.pathname === '/agenda'}>Agenda</Nav.Link>
            <NavDropdown title="Cadastros" id="collasible-nav-dropdown" active={rotaCadastro}>
              <NavDropdown.Item as={Link} to="/empresas">Empresas</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/estagiarios">Estagiários</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/instituicao_educacao">Instituição de educação</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/grupos">Grupos</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/contatos">Contatos</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Contratos" id="collasible-nav-dropdown" active={rotaContrato}>
              <NavDropdown.Item as={Link} to="/contratos">Contratos</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/relatorios/contratos_ativos">Contratos ativos</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/relatorios/contratos_desligados">Contratos desligados</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/relatorios/contratos_expirados">Contratos expirados</NavDropdown.Item>
            </NavDropdown>
            {profile.financial_access && (
              <Nav.Link as={Link} to="/relatorios/financeiro" active={location.pathname === '/relatorios/financeiro'}>Financeiro</Nav.Link>
            )}
            <Nav.Link as={Link} to="/cursos" active={location.pathname === '/cursos'}>Cursos</Nav.Link>
            <NavDropdown title="Pedagógico" id="collasible-nav-dropdown" active={rotaPedagogico}>
              <NavDropdown.Item as={Link} to="/relatorios/presencas">Relatório de presenças</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/relatorios/presencas/contagem">Contagens de presenças</NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
            <NavDropdown title={profile.name} id="collasible-nav-dropdown" >
              <NavDropdown.Item onClick={handleSignOut}>Sair</NavDropdown.Item>
            </NavDropdown>
        </Nav>
      </Navbar >
      {showPresentation && <PresentationScreen onClose={() => setShowPresentation(false)} />}
      {children}
    </>
  );
}

export default DefaultLayout;
