import React from 'react';
import { Control } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Label from '../Label';
// import { Container } from './styles';

interface TextInputProps {
  label: string;
  name: string;
  control: Control;
  type?: 'text' | 'number' | 'password';
  placeholder?: string;
  errorMessage?: string;
  md?: string;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
  className?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  label,
  placeholder,
  type = 'text',
  name,
  errorMessage,
  md = 4,
  required = false,
  className,
  ...rest
}) => {
  return (
    <Form.Group as={Col} md={md} controlId={`form${label}`}>
      <Label text={label} required={required} />

      <Form.Control
        type={type}
        placeholder={placeholder}
        name={name}
        isInvalid={!!errorMessage ?? 'error'}
        className={className}  
        {...rest}
      />
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default TextInput;
