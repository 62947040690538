import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { yupResolver } from '@hookform/resolvers';

import api from 'src/services/axios';

import InputDate from '../../components/InputDate';

import Select from '../../components/Select';

import schema from './schema'
import { toast } from 'react-toastify';

const DesligamentoForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const {id} = useParams();

  const { handleSubmit, control, errors } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      reason_shutdown: '',
      date_shutdown: undefined,
    }
  });

  const onSubmit = data => {
    setLoading(true)
    api.put(`/contracts/${id}/shutdown`, {
      reason_shutdown: data.reason_shutdown.value,
      date_shutdown: data.date_shutdown,
    }).then(() => {
      history.goBack();
      toast.success("Desligamento efetivado com sucesso!");
    }).catch(() => {
      toast.error("Houve um erro ao estabelecer o desligamento!");
    })
  };


  return (
    <Container>
      <Card style={{boxShadow:'var(--card-bs)'}}>
        <Card.Header as='h5' style={{border:'none',color:'var(--secondary-color)'}} >
      {`Desligamento: ${location?.state?.data?.trainee_name} - [${location?.state?.data?.company_name}]`}
        </Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="reason_shutdown"
                label="Qual o motivo ?"
                md="12"
                errorMessage={errors.reason_shutdown?.message}
                options={[
                  {
                    value: 'Contratação do estagiário em regime CLT',
                    label: 'Contratação do estagiário em regime CLT',
                  },
                  {
                    value: ' Por iniciativa da Instituição de Ensino (excesso de faltas ou conduta que desabone o aluno)',
                    label: ' Por iniciativa da Instituição de Ensino (excesso de faltas ou conduta que desabone o aluno)',
                  }, {
                    value: 'Situação irregular de matrícula do estagiário (matriculado em curso diferente ao contrato)',
                    label: 'Situação irregular de matrícula do estagiário (matriculado em curso diferente ao contrato)',
                  }, {
                    value: ' Por iniciativa do estagiário.',
                    label: ' Por iniciativa do estagiário.',
                  }, {
                    value: 'Término de período do contrato de estágio.',
                    label: 'Término de período do contrato de estágio.',
                  }, {
                    value: 'Abandono de curso',
                    label: 'Abandono de curso',
                  },
                  {
                    value: 'Solicitação da empresa',
                    label: 'Solicitação da empresa',
                  }
                ]}
                required
              />

            </Form.Row>
            <Form.Row>
              <Controller
                as={InputDate}
                control={control}
                name="date_shutdown"
                label="Data do desligamento"
                md="12"
                required
                errorMessage={errors.date_shutdown?.message}
              />

            </Form.Row>
          </Card.Body>
          <Card.Footer>
            <Form.Row>
              <Button className="mr-3" type="submit" variant="success">{loading ? 'Carregando...' : 'Salvar'}</Button>
              <Button onClick={() => history.goBack()} variant="outline-secondary">Cancelar</Button>
            </Form.Row>
          </Card.Footer>
        </Form>
      </Card>
    </Container>
  );
}

export default DesligamentoForm;