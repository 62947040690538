import React from 'react';
import { Control } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Label from '../Label';
// import { Container } from './styles';

interface TextareaProps {
  label: string;
  name: string;
  control: Control;
  placeholder?: string;
  errorMessage?: string;
  md?: string;
  defaultValue?: string;
  disabled?: boolean;
  required?: boolean;
}

const Textarea: React.FC<TextareaProps> = ({
  label,
  placeholder,
  name,
  errorMessage,
  defaultValue = '',
  md = 4,
  required = false,
  ...rest
}) => {
  return (
    <Form.Group as={Col} md={md} controlId={`form${label}`}>
      <Label text={label} required={required} />

      <Form.Control
        as="textarea" 
        rows={3}
        placeholder={placeholder}
        name={name}
        isInvalid={!!errorMessage ?? 'error'}
        defaultValue={defaultValue}
        {...rest}
      />
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default Textarea;
