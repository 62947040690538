import React, { useCallback, useEffect, useState } from 'react';
import {useParams } from 'react-router-dom';

// Importações do react-bootrap
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import theme from 'src/styles/theme';
import api from 'src/services/axios';
import { toast } from 'react-toastify';

const DocumentsEmpresas: React.FC = () => {

  const params = useParams();
  const [files, setFiles] = useState<any>([]);

  const handleGetDocuments = useCallback(() => {
    api.get(`/trainees/documents?trainee_id=${params.id}`)
      .then(response => {
        setFiles(response.data.map(file => {
          return {
            id: file.id,
            file_id: file.files.id,
            type: file.files.content_type,
            url: file.files.url,
            name: file.files.name
          }
        }))
      })
      .catch(() => {
        toast.error('Houve um erro ao tentar recuperar os documentos desse estágiário');
      })
  }, [params.id])

  useEffect(() => {
    if (params.id) {
      handleGetDocuments();
    }
  }, [handleGetDocuments, params.id]);


  return (
    <>
      <Container className="mt-3">
        <Card>
          <Card.Header
            style={{
              color: theme.text.colors.dark,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 20px',
            }}
          >
            <span style={{ fontSize: '22px' }}>Documentos </span>
          </Card.Header>
          <Card.Body>

  
            {
              files.map(file => (
                <div key={file.id}>
                  <Card className="mb-4">
                    <Card.Body>
                      <div  className="d-flex justify-content-between  align-items-center">
                      
                        {
                          file.type.includes('image') && (
                            <>
                              <span className="ml-2"> {file.name}</span>
                              <img src={file?.url} className="img-thumbnail" style={{
                                display: 'inline-flex',
                                borderRadius: 2,
                                border: '1px solid #eaeaea',
                                marginBottom: 8,
                                marginRight: 8,
                                width: 200,
                                height: 200,
                                padding: 4,
                                boxSizing: 'border-box'
                              }}
                                alt="Documento RG"

                              />
                            </>
                          )
                        }
                        {
                          file.type.includes('pdf') && (
                            <>
                              <span className="ml-2"> {file.name}</span>
                              <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Icon_pdf_file.svg/1200px-Icon_pdf_file.svg.png'} className="img-thumbnail" style={{
                                display: 'inline-flex',
                                borderRadius: 2,
                                border: '1px solid #eaeaea',
                                marginBottom: 8,
                                marginRight: 8,
                                width: 200,
                                height: 200,
                                padding: 4,
                                boxSizing: 'border-box'
                              }}
                                alt="Documento pdf"


                              />
                            </>
                          )
                        }
                      </div>
                      <button className="btn btn-primary" onClick={() => window.open(file.url)}>
                        Ver arquivo
                      </button>
                    </Card.Body>
                  </Card>
                </div>
              ))
            }
          </Card.Body>
          <Card.Footer>
            <Form.Row>
              <Button className="mr-3" type="submit" variant="success">Salvar</Button>
            </Form.Row>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default DocumentsEmpresas;
