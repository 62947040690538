export const ReplaceOnlyDigits = (text: string) => {
  return text.replace(/[^\d]+/g, '');
};


export const compareTraineeName = (a, b) => {
  // Use toUpperCase() to ignore character casing
  
  const bandA = a.trainee_name.toUpperCase();
  const bandB = b.trainee_name.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}


export const compareDate = (a, b) => {
  // Use toUpperCase() to ignore character casing
  
  const bandA = a.date.toUpperCase();
  const bandB = b.date.toUpperCase();

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}