import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// Importações do react-bootrap
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import theme from 'src/styles/theme';
import { useDropzone } from 'react-dropzone';
import api from 'src/services/axios';
import { toast } from 'react-toastify';
import { CiImport } from 'react-icons/ci';

const DocumentsCompanies: React.FC = () => {
  const location = useLocation();
  const params = useParams();

  const [currentFile, setCurrentFile] = useState(null);

  const [files, setFiles] = useState<any>([]);

  const [newFiles] = useState<any>([]);

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
    newFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files, newFiles]);

  const handleGetDocuments = useCallback(() => {
    api.get(`/companies/documents?company_id=${params.id}`)
      .then(response => {
        setFiles(response.data.map(file => {
          return {
            id: file.id,
            file_id: file.files.id,
            type: file.files.content_type,
            url: file.files.url,
            name: file.files.name
          }
        }))
      })
      .catch(() => {
        toast.error('Houve um erro ao tentar recuperar os documentos');
      })
  }, [params.id])

  useEffect(() => {
    if (params.id) {
      handleGetDocuments();
    }
  }, [handleGetDocuments, params.id]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*', '.pdf'],
    onDrop: async (acceptedFiles) => {

      try {
        const formData = new FormData();
        formData.append('image', acceptedFiles[0]);

        await api.post(`/files?type_upload=company_document_upload&file_id=${currentFile}`, formData);

        toast.success("Atualizado com sucesso");
        handleGetDocuments();
      } catch {
        toast.error("Houve um erro ao tentar atualizar o documento");
      }
    }
  });

  const { getRootProps: getRootPropsNewFile, getInputProps: getInputPropsNewFile } = useDropzone({
    accept: ['image/*', '.pdf'],
    onDrop: async (acceptedFiles) => {

      try {
        const formData = new FormData();
        formData.append('image', acceptedFiles[0]);

        await api.post(`/files?company_id=${params.id}&type_upload=company_document_create`, formData);

        toast.success("Adicionado com sucesso");

        handleGetDocuments();
      } catch {
        toast.error("Houve um erro ao tentar adicionar novo documento");
      }
    }
  });

  return (
    <>
      <Container className="mt-3">
        <Card>
          <Card.Header
            style={{
              color: theme.text.colors.dark,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 20px',
            }}
          >
            <span style={{ fontSize: '22px' }}>Documentos da empresa: {`${location?.state?.name}`}</span>
          </Card.Header>
          <Card.Body>

            <Card className="mt-4 mb-4" style={{ border: '1px dashed #999',boxShadow:'none' }}>
              <Card.Body>
                <div  {...getRootPropsNewFile({ className: 'dropzone' })} className="d-flex justify-content-center align-items-center">
                  <input {...getInputPropsNewFile()} />
                  <div style={{ color: 'var(--gray-400)', }} className='interaction'>
                  <CiImport size={25} className='mr-2' />
                  Clique aqui para adicionar um novo documento em imagem ou PDF
                  </div>
                </div>
              </Card.Body>
            </Card>

            <hr />
            {
              files.map(file => (
                <div key={file.id}>
                  <Card className="mb-4" onClick={() => { setCurrentFile(file.file_id) }}>
                    <Card.Body>
                      <div  {...getRootProps({ className: 'dropzone' })} className="d-flex justify-content-between  align-items-center">
                        <input {...getInputProps()} />
                        {
                          file.type.includes('image') && (
                            <>
                              <span className="ml-2"> {file.name}</span>
                              <img src={file?.url} className="img-thumbnail" style={{
                                display: 'inline-flex',
                                borderRadius: 2,
                                border: '1px solid #eaeaea',
                                marginBottom: 8,
                                marginRight: 8,
                                width: 200,
                                height: 200,
                                padding: 4,
                                boxSizing: 'border-box'
                              }}
                                alt="Documento"

                              />
                            </>
                          )
                        }
                        {
                          file.type.includes('pdf') && (
                            <>
                              <span className="ml-2"> {file.name}</span>
                              <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Icon_pdf_file.svg/1200px-Icon_pdf_file.svg.png'} className="img-thumbnail" style={{
                                display: 'inline-flex',
                                borderRadius: 2,
                                border: '1px solid #eaeaea',
                                marginBottom: 8,
                                marginRight: 8,
                                width: 200,
                                height: 200,
                                padding: 4,
                                boxSizing: 'border-box'
                              }}
                                alt="Documento pdf"
                              />
                            </>
                          )
                        }
                      </div>
                      <button className="btn btn-primary" onClick={() => window.open(file.url)}>
                        Ver arquivo
                      </button>
                    </Card.Body>
                  </Card>
                </div>
              ))
            }
          </Card.Body>
          <Card.Footer>
            <Form.Row>
              <Button className="mr-3" type="submit" variant="success">Salvar</Button>
            </Form.Row>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
}

export default DocumentsCompanies;
