import * as yup from 'yup';

const schema = yup.object().shape({
  trainee_id: yup.object().required('Campo obrigatório').nullable(true),
  company_id: yup.object().required('Campo obrigatório').nullable(true),
  start_validity: yup.date().required('Campo obrigatório').nullable(true),
  end_validity: yup.date().required('Campo obrigatório').nullable(true),
  duration: yup.number().required('Campo obrigatório'),
  work_activities: yup.string().required('Campo obrigatório'),
  internship_scholarship_value: yup.number().required('Campo obrigatório'),
  transportation_assistance_value: yup.number().required('Campo obrigatório'),
  educational_institution_id: yup.object().required('Campo obrigatório').nullable(true),
  is_higher_education: yup.object().required('Campo obrigatório').nullable(true),
  course_id: yup.object().required('Campo obrigatório').nullable(true),
});

export default schema;