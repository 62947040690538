import React from 'react';

import Label from '../Label';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import IntlCurrencyInput from 'react-intl-currency-input';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export interface InputMaskProps {
  name: string;
  label?: string;
  md?: string;
  errorMessage?: string;
  defaultValue: string;
  required?: boolean;
}

const InputMoney = ({
  label = '',
  md = '4',
  errorMessage = '',
  required = false,
  onChange,
  ...rest
}) => {
  return (
    <Form.Group as={Col} md={md} controlId={`form${label}`}>
      <Label text={label} required={required} />
      <IntlCurrencyInput
        component={Form.Control}
        currency="BRL"
        config={currencyConfig}
        onChange={(_event, value) => onChange(value)}
        {...rest}
      />
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default React.memo(InputMoney);
