import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

// Importações do bootstrap
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import swal from 'sweetalert'
import axios from 'axios'

import MaskedInput from '../../../components/InputMask';
import Input from '../../../components/Input';

import schema from './schema'
import api from 'src/services/axios';
import InputMask from '../../../components/InputMask';
import { toast } from 'react-toastify';
import InputMoney from '../../../components/InputMoney';
import InputDate from '../../../components/InputDate';
import { parseISO } from 'date-fns';

import Select from '../../../components/Select';

const optionsCharge = [
  {
    value: 'INTEGRAL',
    label: 'Integral'
  },
  {
    value: 'PROPORTIONAL',
    label: 'Proporcional'
  }
]

interface Option {
  value: string
  label: string
}

const EmpresaForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [optionsGroup, setOptionsGroup] = useState<Option[]>([])
  const [loadingOptions, setLoadingOptions] = useState(false)

  const params = useParams();

  const history = useHistory();

  const { handleSubmit, control, watch, setValue, errors } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      cnpj: '',
      company_name: '',
      contact: '',
      cep: '',
      address: '',
      neighborhood: '',
      city: '',
      state: '',
      number: '',
      complement: '',
      rh_analyst: '',
      supervisor: '',
      institution_value: '',
      integration_agent_value: '',
      agreement_start_date: undefined,
      type_charge: null,
      fantasy_name: '',
      group: null,
      integration_agent_higher_education_value: '',
      amount_clt: 0
    }
  });


  const watchCep = watch('cep', '');

  useEffect(() => {
    if (watchCep.length === 9 && !params.id) {
      swal({
        title: "Buscar CEP automaticamente ?",
        icon: "warning",
        buttons: ["Cancelar", true],
      })
        .then((value) => {
          if (value) {
            axios.get(`https://viacep.com.br/ws/${watchCep}/json/`)
              .then(response => {
                setValue('neighborhood', response.data?.bairro);
                setValue('address', response.data?.logradouro);
                setValue('city', response.data?.localidade);
                setValue('state', response.data?.uf);
                setValue('complement', response.data?.complemento);
              })
          }
        });
    }
  }, [watchCep, setValue, params.id]);


  useEffect(() => {
    if (params.id && loadingOptions) {
      api.get(`/companies/${params.id}`)
        .then(response => {
          // @ts-ignore
    
          setValue('type_charge', optionsCharge.find(item => item.value === response.data.type_charge));
          setValue('group', optionsGroup.find(item => item.value === response.data.group_id) as any);
          setValue('fantasy_name', response.data.fantasy_name);
          setValue('cnpj', response.data.cnpj);
          setValue('company_name', response.data.company_name);
          setValue('contact', response.data.contact);
          setValue('cep', response.data.cep || '');
          setValue('address', response.data.address);
          setValue('neighborhood', response.data.neighborhood);
          setValue('state', response.data.state);
          setValue('city', response.data.city);
          setValue('number', response.data.number);
          setValue('complement', response.data.complement);
          setValue('rh_analyst', response.data.rh_analyst);
          setValue('supervisor', response.data.supervisor);
          setValue('integration_agent_value', response.data.integration_agent_value);
          setValue('integration_agent_higher_education_value', response.data.integration_agent_higher_education_value);
          setValue('institution_value', response.data.institution_value);
          //@ts-ignore
          setValue('agreement_start_date', response.data.agreement_start_date ? parseISO(response.data.agreement_start_date.substring(0,10)) : '');
          setValue('amount_clt', response.data.amount_clt);
        })
    }
  }, [params.id, setValue, loadingOptions]);

  const onSubmit = data => {
    setLoading(true)
    const company = {
      cnpj: data.cnpj,
      company_name: data.company_name,
      contact: data.contact,
      cep: data.cep,
      address: data.address,
      neighborhood: data.neighborhood,
      city: data.city,
      state: data.state,
      number: data.number,
      complement: data.complement,
      rh_analyst: data.rh_analyst,
      supervisor: data.supervisor,
      integration_agent_value: data.integration_agent_value,
      institution_value: data.institution_value,
      agreement_start_date: data.agreement_start_date,
      type_charge: data.type_charge.value,
      fantasy_name: data.fantasy_name,
      group_id: data.group?.value,
      integration_agent_higher_education_value: data.integration_agent_higher_education_value,
      amount_clt: data.amount_clt
    };


    if (params.id) {

      api.put(`/companies/${params.id}`, company)
        .then(() => {
          history.goBack();
          toast.success("Atualizado com sucesso!");
        }).catch(() => {
          toast.error("Houve um erro ao atulizar a empresa!");
        })
    } else {
      api.post('/companies', company).then(() => {
        history.goBack();
        toast.success("Cadastrado com sucesso!");
      }).catch(() => {
        toast.error("Houve um erro ao cadastrar a empresa!");
      })
    }
  };

  useEffect(() => {
    api.get('/groups')
      .then(response => {
        setOptionsGroup(response.data.map(item => ({
          value: Number(item.id),
          label: item.name
        }))
        )
      })
      .finally(() => setLoadingOptions(true))
  }, []);

  return (
    <Container>
      <Card style={{ boxShadow: 'var(--card-bs)', background: 'var(--gray-100)',overflow:'hidden' }}>
        <Card.Header style={{ border: 'none', background: 'transparent', color: 'var(--secondary-color)' }} as='h5' >
          Nova Empresa
        </Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body style={{ margin: '0.5rem 1rem', background: 'white', borderRadius: '10px' }} >
            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name="fantasy_name"
                label="Nome fantasia"
                md="4"
              />
              <Controller
                as={Input}
                control={control}
                name="company_name"
                label="Razão Social"
                errorMessage={errors.company_name?.message}
                md="5"
                required
              />
              <Controller
                as={Select}
                control={control}
                name="group"
                label="Qual o grupo ?"
                md="3"
                options={optionsGroup}
              />

            </Form.Row>
            <Form.Row>
              <Controller
                as={MaskedInput}
                control={control}
                name="cnpj"
                mask="cnpj"
                label="CNPJ"
                errorMessage={errors.cnpj?.message}
                md="4"
                required
              />
              <Controller
                as={InputMask}
                mask="tel"
                control={control}
                name="contact"
                label="Telefone da empresa"
                md="4"
              />
              <Controller
                as={InputDate}
                control={control}
                name="agreement_start_date"
                label="Data da parceria"
                md="4"
              />
            </Form.Row>
            <hr />
            <Form.Row>
              <Controller
                as={MaskedInput}
                control={control}
                name="cep"
                mask="cep"
                label="CEP"
                md="3"
              />
              <Controller
                as={Input}
                control={control}
                name="address"
                label="Endereço"
                md="3"
              />
              <Controller
                as={Input}
                control={control}
                name="neighborhood"
                label="Bairro"
                md="3"
              />
              <Controller
                as={Input}
                control={control}
                name="city"
                label="Cidade"
                md="3"
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name="state"
                label="Estado"
                md="3"
              />
              <Controller
                as={Input}
                control={control}
                name="number"
                label="Número"
                md="3"

              />
              <Controller
                as={Input}
                control={control}
                name="complement"
                label="Complemento"
                md="6"
              />
            </Form.Row>
            <hr />
            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name="rh_analyst"
                label="E-mail RH"
                md="6"
              />
              <Controller
                as={Input}
                control={control}
                name="supervisor"
                label="E-mail Financeiro"
                md="6"
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={InputMoney}
                control={control}
                name="integration_agent_value"
                label="Taxa Ag. Integração"
                md="4"
              />
              <Controller
                as={InputMoney}
                control={control}
                name="institution_value"
                label="Taxa Escola"
                md="4"
              />
              <Controller
                as={InputMoney}
                control={control}
                name="integration_agent_higher_education_value"
                label="Taxa Ag. Integração Ens. Superior"
                md="4"
              />
            </Form.Row>
            <hr />
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name="type_charge"
                label="Qual o tipo de cobrança ?"
                md="6"
                options={optionsCharge}
                errorMessage={errors.type_charge?.message}
              />
              <Controller
                as={Input}
                control={control}
                name="amount_clt"
                label="Quantidade de Clt´s"
                md="6"
              />
            </Form.Row>
          </Card.Body>
          <Card.Footer style={{ background: 'white' }}>
            <Form.Row>
              <Button className="mr-3 button-primary" type="submit" disabled={loading} variant="success">{loading ? 'Carregando...' : 'Salvar'}</Button>
              <Button className='button-secondary-outline' onClick={() => history.goBack()} variant="outline-secondary">Cancelar</Button>
            </Form.Row>
          </Card.Footer>
        </Form>
      </Card>
    </Container>
  );
}

export default EmpresaForm;