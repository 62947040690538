import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

export const Abbr = styled.abbr`
  color: red;
  margin-right: 2px;
`;

export const LabelForm = styled(Form.Label)`
  color: #7d7d8e;
  font-weight: normal;
  margin: 0;
  font-size: 15px;
`;
