import * as yup from 'yup';

const schema = yup.object().shape({
  cnpj: yup
    .string()
    .required('Campo obrigatório')
    .min(14, 'Valor está incompleto'),
  name: yup
    .string()
    .required('Campo obrigatório'),
  contact: yup
    .string()
    .required('Campo obrigatório'),
  cep: yup
    .string()
    .required('Campo obrigatório'),
  address: yup
    .string()
    .required('Campo obrigatório'),
  neighborhood: yup
    .string()
    .required('Campo obrigatório'),
  city: yup
    .string()
    .required('Campo obrigatório'),
  state: yup
    .string()
    .required('Campo obrigatório'),
  number: yup
    .string()
    .required('Campo obrigatório'),
});

export default schema;