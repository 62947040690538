import React, { useEffect, useState } from 'react'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ptBrLocaleCalendar from '@fullcalendar/core/locales/pt-br'

import { Card, Container, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Swal from 'sweetalert2'

import api from 'src/services/axios'
import { toast } from 'react-toastify'

import './style.css'
import { format , parseISO} from 'date-fns'

interface CurrentEvent {
  id: string
  title: string
  start: string
  observations: string
  createdBy: string
}

export default function Schedule() {
  const [currentEvents, setCurrentEvents] = useState<CurrentEvent[]>([])
  const [onlyMe, setOnlyMe] = useState(false)

  function handleDateSelect(selectInfo) {
    Swal.fire({
      title: "Qual o evento ?",
      html: `
        <input id="text-input" class="swal2-input" placeholder="Descrição">
        <input id="hour-input" type="time" class="swal2-input">
        <input id="observations-input" class="swal2-input" placeholder="Observações">
        <div>
        <p>Tipo de evento:</p>
        <select class="form-control" id="private-input"><option value="true">Privado</option><option value="false" selected>Público</option></select>
        </div>
    
      `,
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: "Adicionar",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const description = document.getElementById("text-input") as HTMLInputElement
        const hour = document.getElementById("hour-input") as HTMLInputElement
        const observations = document.getElementById("observations-input") as HTMLTextAreaElement
        const privateEvent = document.getElementById("private-input") as HTMLInputElement


        if (description.value && hour.value) {
          try {
            await api.post('/schedules', {
              description: description.value,
              date: selectInfo.startStr,
              hour: hour.value,
              observations: observations.value,
              private: (privateEvent.value)
            })

       

            let calendarApi = selectInfo.view.calendar
            calendarApi.unselect()
            getSchedule();
          } catch {
            Swal.showValidationMessage(`
              Falha ao adicionar o evento
            `);
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    })
  }

  const handleEventClick = (clickInfo) => {
    const allInformationEvent = currentEvents.find(item => String(item.id) === String(clickInfo.event.id)) as CurrentEvent

    Swal.fire({
      title: allInformationEvent.title,
      confirmButtonColor: 'red',
      showCancelButton: true,
      html: `
      <h5 class="text-center color-secondary">${allInformationEvent.start}</h5>
      <hr/>
      <p style='font-weight:bold;color:var(--secondary-color);text-align:start'>Criado por: ${allInformationEvent.createdBy}</p>
      <p style='font-weight:bold;color:var(--secondary-color);text-align:start'>Observações:</p>
      <textarea  style='width:100%;resize:none;border-radius:10px;padding:0.5rem 0' disabled rows='4' cols='12'>
      ${allInformationEvent.observations || 'Sem observações para esse evento'}
      </textarea>

      `,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Remover Evento'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await api.delete(`/schedules/${clickInfo.event.id}`)
        getSchedule();
      }
    });
  }

  const getSchedule = () => {
    api.get(`schedules?me=${onlyMe}`)
      .then(response => {
        setCurrentEvents(response.data.map(item => ({
          ...item,
          date:  `${format(parseISO(item.date.substring(0,10)),'dd-MM-yyyy')}`,
          start:  `${format(parseISO(item.date.substring(0,10)),'yyyy-MM-dd')} ${item.hour}`
        })))
      })
      .catch((error) => {
        console.log(error)
        toast.error("Erro ao buscar calendário")
      })
  }

  useEffect(() => {
    getSchedule();
  }, [onlyMe]);

  return (
    <Container fluid>
      <Card style={{ boxShadow: 'var(--card-bs)' }} className='p-4'>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            type="checkbox"
            label="Visualizar somente minha agenda"
            checked={onlyMe}
            onChange={() => setOnlyMe(!onlyMe)}
            style={{ fontSize: 16 }}
          />
        </Form.Group>

        <div className='demo-app'>
          <Sidebar
            currentEvents={currentEvents}
          />
        </div>
        <div className='demo-app-main'>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,today,next',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            locale={ptBrLocaleCalendar}
            initialView='dayGridMonth'
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            events={currentEvents}
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            themeSystem='Lumen'
          
          />
        </div>
      </Card>
    </Container>
  )
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{`${eventInfo.timeText.length  ? `${eventInfo.timeText}:00` : eventInfo.timeText}-`}</b>
      <OverlayTrigger
        key="term_de_contrato_escola"
        placement="right"
        overlay={
          <Tooltip id={`tooltip-right`}>
            {` ${eventInfo.event.title}`}
          </Tooltip>
        }
      >
        <span>{eventInfo.event.title.substring(0, 20)}...</span>
      </OverlayTrigger>


    </>
  )
}

function Sidebar({ currentEvents }) {
  return (

    <div className='demo-app-sidebar'>
      <div className='demo-app-sidebar-section'>
        <h2>Quantidade de eventos: {currentEvents.length}</h2>
      </div>
    </div>
  )
}

