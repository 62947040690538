
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthLayout from 'src/screens/_layouts/auth';
import CompanyLayout from 'src/screens/_layouts/authCompany';
import DefaultLayout from 'src/screens/_layouts/default';
import { store } from 'src/store';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  permissions = [],
  ...rest
}) {
  const { signed, company } = store.getState().auth;
 
  
  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to={!company ? "/dashboard" : '/relatorios/empresas/presencas'} />;
  }

  let Layout = CompanyLayout

  if(signed && !company) Layout =  DefaultLayout
  else if(!signed) Layout =  AuthLayout

  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Layout>
            <Component {...props} />
          </Layout>
        </>
      )}
    />
  );
}

