/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Image } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import { signInRequest } from 'src/store/modules/auth/actions';

import LogoDrEstagio from '../../assets/Images/Logo.svg';
import './style.css';

const Login: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      cpf: '',
      password: '',
    }
  });

  const onSubmit = useCallback(
    async (data: { cpf: string; password: string }) => {
      try {
        setLoading(true);
        // Executa a ação de login
        await dispatch(signInRequest(data.cpf, data.password));
      } catch (error) {
        // Trate o erro se necessário, como mostrar uma mensagem de erro
        console.error('Erro no login:', error);
      } finally {
        // Garante que o estado de loading volte a ser false
        setLoading(false);
      }
    },
    [dispatch]
  );

  return (
    <section className='h-100vh w-full login-bg'>
      <div className="login__container">
        <div className="login__content">
         
          <Form onSubmit={handleSubmit(onSubmit)} className="login__form">
            <Image
              className='text-center'
              style={{ margin: '0 auto', filter: 'brightness(10) !important' }}
              src={LogoDrEstagio}
            />
            <div>
              <h1 className="login__title">
                <span>Bem vindo</span> ao Estagius.
              </h1>
              <p className="login__description">
                Faça o login para continuar.
              </p>
            </div>

            <div>
              <div className="login__inputs">
                <div>
                  <Controller
                    as={InputMask}
                    control={control}
                    mask="cpf"
                    name="cpf"
                    label='CPF'
                    className='login__input'
                    md="12"
                    required
                  />
                </div>

                <div>
                  <div className="login__box">
                    <Controller
                      as={Input}
                      control={control}
                      type="password"
                      name="password"
                      label='Senha'
                      className='login__input'
                      md="12"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="login__buttons">
                <Button
                  className="login__button"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? 'Carregando...' : 'Logar'}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </section>
  );
}

export default Login;
