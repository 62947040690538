import * as yup from 'yup';

const schema = yup.object().shape({
    company_name: yup
        .string()
        .required('Campo obrigatório'),
    name: yup
        .string()
        .required('Campo obrigatório'),
    main_contact: yup
        .string()
        .required('Campo obrigatório')
        .nullable(true)
});

export default schema;