import React from 'react';
import { LabelForm, Abbr } from './styles';

export interface LabelProps {
  text: string;
  required?: boolean;
}

const Label: React.FC<LabelProps> = ({ text, required = false }) => {
  return (
    <LabelForm>
      {required && <Abbr title="Preenchimento Obrigatório">*</Abbr>}
      {text}
    </LabelForm>
  );
};

export default React.memo(Label);
