import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { addMonths, format, parseISO, subMonths } from 'date-fns';

// Componentes do react-bootrap
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';

import ComplexTable from '../../../components/ComplexTable';
import api from 'src/services/axios';
import { ptBR } from 'date-fns/locale';
import { toast } from 'react-toastify';

const ExpiredContracts: React.FC = () => {

  const [contracts, setContracts] = useState([]);

  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());

  const monthName = useMemo(() => format(currentMonth, "MMMM 'de' yyyy", { locale: ptBR }).toUpperCase(), [currentMonth]);

  const handleAddMonth = useCallback(() => setCurrentMonth(addMonths(currentMonth, 1)), [currentMonth]);
  const handleSubMonth = useCallback(() => setCurrentMonth(subMonths(currentMonth, 1)), [currentMonth]);

  const handleSetReport = useCallback((data) => {
    setContracts(data.map(item => {
      return {
        id: item.id,
        trainee_name: item.trainee?.name,
        trainee_primary_phone_contact: item.trainee?.primary_phone_contact,
        company_name: item?.company?.company_name,
        number_days_end_contract: item.numberDaysEndContract,
        status: item?.status,
        end_validity: format(parseISO(item.end_validity), 'dd/MM/yyyy')
      }
    }));
  }, []);

  useEffect(() => {
    const year = format(currentMonth, "yyyy");
    const month = format(currentMonth, "MM");

    api
      .get(`/contractExpirationReport?year=${year}&month=${month}`).then((response) => {
        handleSetReport(response.data);
      })
      .catch(() => toast("Erro ao listar relatório"))
  }, [currentMonth]);

  return (
    <Container fluid>
      <Card style={{ boxShadow: 'var(--card-bs)' }}>
        <Card.Header as="h5" className='color-secondary' >
          <i className="bi bi-stickies-fill mr-2"></i>
          Relatórios de contratos expirados
        </Card.Header>
        <Card.Body>

          <div
            className="mb-4 d-flex justify-content-between p-2 align-items-center"
            style={{
              background: 'var(--gray-100)',
              border: '4px solid',
              borderColor: 'transparent var(--secondary-color) transparent var(--secondary-color)'
            }}>
            <div>
              <Button onClick={handleSubMonth} className='month-directions-icons float-left'>
                <i className="bi bi-arrow-left-square-fill mr-2"></i>
                <p>Anterior</p>
              </Button>
            </div>
            <div style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold', display: 'flex', color: 'var(--secondary-color)' }}>
              <i style={{ color: 'var(--secondary-color)' }} className="bi bi-calendar-fill mr-2 fs-2"></i>
              {monthName}
            </div>
            <div>
              <Button onClick={handleAddMonth} className='month-directions-icons float-right'>
                <p>Próximo</p>
                <i className="bi bi-arrow-right-square-fill ml-2"></i>
              </Button>
            </div>
          </div>

          <ComplexTable
            data={contracts}
            columns={[
              {
                dataField: 'trainee_name',
                text: 'Estagiário',
                sort: false,
                filter: false,
              },
              {
                dataField: 'trainee_primary_phone_contact',
                text: 'Contato',
                sort: false,
                filter: false,
              },
              {
                dataField: 'company_name',
                text: 'Empresa',
                sort: false,
                filter: false,
              },
              {
                dataField: 'end_validity',
                text: 'Fim do contrato',
                sort: false,
                filter: false,
              },
              {
                dataField: 'number_days_end_contract',
                text: 'Total de dias',
                sort: false,
                filter: false,
                formatter: (_, row) => (
                  <>
                    {
                      row.number_days_end_contract > 0 ? (
                        <Badge pill variant="warning" style={{ fontSize: '14px' }}>
                          Para expirar ({row.number_days_end_contract})
                        </Badge>) : (
                        <Badge pill variant="danger" style={{ fontSize: '14px' }}>
                          Expirado ({row.number_days_end_contract})
                        </Badge>
                      )
                    }
                  </>
                )
              },

            ]}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ExpiredContracts;
