import React from 'react'
import { Button, Card, Container, Form } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import Input from '../../components/Input';
import { UploadImageField } from '../../components/UploadImageField/UploadImageField';
import { FaUserEdit, FaUsers } from 'react-icons/fa';
import { FaBuildingUser } from 'react-icons/fa6';

export function Profile() {
    const { handleSubmit, control, setValue, errors } = useForm({
        reValidateMode: 'onSubmit',
        defaultValues: {
            name: '',
            password: '',
        }
    });

    const handleChangeSelectPhoto = (value: File) => setValue('photo', value)

    return (
        <Container>
            <Card style={{ boxShadow: 'var(--card-bs)' }}>
                <Card.Header as='h5' style={{ border: 'none', color: 'var(--secondary-color)' }}>
                    Editando o Perfil
                </Card.Header>
                <form>
                    <Card.Body>
                        <div className='d-flex flex-column align-items-center justify-content-center w-100 p-4'>
                            <UploadImageField handleChangeSelectPhoto={handleChangeSelectPhoto} />
                            <div className='d-flex flex-column' style={{ gap: '8px' }}>
                                <h5 className='text-center'>Suas permissões:</h5>
                                <div className='d-flex w-100 align-items-center color-primary'>
                                    <i style={{ fontSize: '1.4rem' }} className="bi bi-file-text-fill mr-2"></i>
                                    <span style={{
                                        padding: '4px 8px',
                                        background: 'var(--primary-light)',
                                        boxShadow: '0 2px 2px #ccc',
                                        borderLeft: '2px solid var(--primary-regular)',
                                        borderRight: '2px solid var(--primary-regular)',
                                        fontFamily: 'monospace',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        color: 'var(--primary-regular)',
                                        textAlign: 'center',
                                        width: '100%'
                                    }}>

                                        Acesso ao financeiro
                                    </span>
                                </div>

                                <div className='d-flex w-100  align-items-center color-secondary'>
                                <FaUsers style={{ fontSize: '1.4rem'}} className='mr-2' />
                                    <span style={{
                                        padding: '4px 8px',
                                        background: 'var(--secondary-light)',
                                        boxShadow: '0 2px 2px #ccc',
                                        borderLeft: '2px solid var(--secondary-color)',
                                        borderRight: '2px solid var(--secondary-color)',
                                        fontFamily: 'monospace',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        color: 'var(--secondary-color)',
                                        textAlign: 'center',
                                        width: '100%'
                                    }}>

                                        Permissões de colaboardor
                                    </span>
                                </div>

                                <div className='d-flex w-100  align-items-center color-secondary'>
                                    <FaBuildingUser  style={{ fontSize: '1.4rem' }} className='mr-2' />
                                    <span style={{
                                        padding: '4px 8px',
                                        background: 'var(--secondary-light)',
                                        boxShadow: '0 2px 2px #ccc',
                                        borderLeft: '2px solid var(--secondary-color)',
                                        borderRight: '2px solid var(--secondary-color)',
                                        fontFamily: 'monospace',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        color: 'var(--secondary-color)',
                                        textAlign: 'center',
                                        width: '100%'
                                    }}>

                                        Permissões de empresa
                                    </span>
                                </div>
                            </div>
                        </div>
                        <Form.Row>
                            <Controller
                                as={Input}
                                control={control}
                                name="name"
                                label="Nome do perfil"
                                errorMessage={errors.name?.message}
                                md="6"
                                required
                            />

                            <Controller
                                as={Input}
                                control={control}
                                name="password"
                                label="Alterar senha"
                                errorMessage={errors.name?.message}
                                md="6"
                                type='password'
                                required
                            />
                        </Form.Row>
                    </Card.Body>
                    <Card.Footer>
                        <Button className='button-primary' type='submit'>
                            <FaUserEdit size={20} className='mr-2' />
                            Salvar alterações
                        </Button>
                    </Card.Footer>
                </form>

            </Card>
        </Container >
    )
}