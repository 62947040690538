import * as yup from 'yup';

const schema = yup.object().shape({
  cnpj: yup
    .string()
    .required('Campo obrigatório')
    .min(14, 'Valor está incompleto'),
  company_name: yup
    .string()
    .required('Campo obrigatório'),
  type_charge: yup
    .object()
    .required('Campo obrigatório')
    .nullable(true)
});

export default schema;