import * as yup from 'yup';

const schema = yup.object().shape({
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .min(14, 'Valor está incompleto'),
  name: yup
    .string()
    .required('Campo obrigatório'),
  rg: yup
    .string()
    .required('Campo obrigatório'),
  primary_phone_contact: yup
    .string()
    .required('Campo obrigatório'),
  gender: yup
    .object()
    .required('Campo obrigatório')
    .nullable(true),
  date_birth: yup
    .string()
    .required('Campo obrigatório'),
  cep: yup
    .string()
    .required('Campo obrigatório'),
  address: yup
    .string()
    .required('Campo obrigatório'),
  neighborhood: yup
    .string()
    .required('Campo obrigatório'),
  city: yup
    .string()
    .required('Campo obrigatório'),
  state: yup
    .string()
    .required('Campo obrigatório'),
  number_home: yup
    .string()
    .required('Campo obrigatório'),
    father_name: yup
    .string()
    .required('Campo obrigatório'),
    mother_name: yup
    .string()
    .required('Campo obrigatório'),
    email: yup.string().email("Digite um email válido").required('campo obrigatorio')
});

export default schema;