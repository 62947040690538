/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container'

import Form from 'react-bootstrap/Form';

import ComplexTable from '../../../components/ComplexTable';
import api from 'src/services/axios';
import Icon from '../../../components/icons'
import { useHistory } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2';

import { toast } from 'react-toastify';
import { FaFileContract } from 'react-icons/fa';
import { CiImport } from "react-icons/ci";

const TraineesList = () => {

  const history = useHistory();
  const [word, setWord] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx',
    onDrop: async acceptedFiles => {

      await Swal.fire({
        title: 'Qual o tipo de inserção ?',
        input: 'select',
        inputOptions: {
          'PRESENÇA': 'PRESENÇAS',
          'FALTA': 'FALTAS',
          'PRESENÇA AMBIENTAÇÃO': 'PRESENÇA AMBIENTAÇÃO',
          'FALTA AMBIENTAÇÃO': 'FALTA AMBIENTAÇÃO'
        },
        inputPlaceholder: 'Selecione um tipo',
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === 'PRESENÇA' || value === 'FALTA' || value === 'PRESENÇA AMBIENTAÇÃO' || value === 'FALTA AMBIENTAÇÃO') {
              Swal.fire({
                title: 'Atenção',
                text: 'Deseja realmente importar esse arquivo ? A ação feita não poderá ser desfeita.',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Sim`,
                denyButtonText: `Voltar`
              }).then(async (result) => {
              
                if (result.isConfirmed) {
                  try {
                    const formData = new FormData();
                    formData.append('file', acceptedFiles[0]);

                    const response = await api.post(`trainees/attendance?type=${value}`, formData)

                    Swal.fire({
                      title: `${response.data.count.length} registros processados`,
                      icon: 'info',
                      width: '70%',
                      html: response.data.count.map(item => {

                        if (item.exists === true) return `<p style='color: purple'>CPF: ${item.attendance.cpf} | ${item.attendance.type}</p>`
                        else if (item.exists === false) return `<p style='color: green'>CPF: ${item.attendance.cpf} | ${item.attendance.type}</p>`
                        return <p>Não processado</p>
                      })
                    })
                   
                  } catch (error) {
                 
                    Swal.fire('Houve um erro!', '', 'error')
                  }
                }
              })

            } else {
              resolve('Selecione um tipo')
            }
          })
        }
      })
    }
  });

  const [trainees, setTrainees] = useState([]);

  const handleGetTrainnes = async (e) => {
    e.preventDefault();
    api.get(`trainees?cpf=${word}`).then((response) => {
      setTrainees(response.data.map(item => {

        const activeContract = item.contracts.find(contract => contract.status)

        return {
          id: item.id,
          name: item.name.toUpperCase(),
          cpf: item.cpf,
          primary_phone_contact: item.primary_phone_contact,
          email: item.email,
          status: activeContract ? activeContract.status : false,
          hasContract: item.contracts.length > 0 ? true : false,
          contract: activeContract || item.contracts.find(contract => !contract.status)
        }
      }));
    })
  }

  useEffect(() => {
    api.get(`trainees?cpf=${word}&default=true`).then((response) => {
      setTrainees(response.data.map(item => {
        return {
          id: item.id,
          name: item.name.toUpperCase(),
          primary_phone_contact: item.primary_phone_contact,
          email: item.email,
          cpf: item.cpf,
          hasContract: false
        }
      }));
    })
  }, []);

  const handleExportClick = () => {
    api.get('trainees/export', { responseType: 'blob' })
      .then(response => {
        // Cria um URL temporário para o arquivo CSV
        const url = URL.createObjectURL(response.data);

        // Cria um link de download para o arquivo CSV
        const link = document.createElement('a');
        link.href = url;
        link.download = 'estagiarios.csv';
        link.click();

        // Libera o URL temporário
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Erro ao exportar CSV:', error);
      });
  };

  const handleDelete = (id) => {


    api.delete(`/trainees/${id}`)
      .then(() => {

        toast.success('Deletado com sucesso')
        setTrainees(trainees.filter(item => Number(item.id) !== Number(id)))

      })
      .catch(error => {
        if (error?.response?.data?.error) {
          toast.error(error.response.data.error)
          return
        }
        toast.error("Houve um erro ao tentar deletar");
      })
  }

  return (
    <Container fluid>
      <Card style={{ boxShadow: 'var(--card-bs)' }}>
        <Card.Header as="h5" className='d-flex justify-content-between align-items-center color-secondary'>
          <div>
            <i className="bi bi-stack mr-2"></i>
            Estagiários
          </div>
          <div>
            <Button
              variant="primary"
              onClick={() => history.push('/estagiarios/novo')}
              className="button-primary mr-4"
            >
              <i className="bi bi-plus-lg mr-2"></i>
              Novo estagiário
            </Button>

            <Button
              variant="primary"
              onClick={handleExportClick}
              className="button-secondary"
            >
              <i className="bi bi-upload mr-2"></i>
              Exportar
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Card className="mt-4 mb-4" style={{ border: '1px dashed #CCC', boxShadow: 'none' }}>
            <Card.Body>
              <div  {...getRootProps({ className: 'dropzone' })} className="d-flex interaction justify-content-center  align-items-center">
                <input {...getInputProps()} />
                <span style={{ color: 'var(--gray-400)', }}>
                  <CiImport size={25} className='mr-2' />
                  Importe aqui o arquivo de presenças
                </span>
              </div>
            </Card.Body>
          </Card>

          <Form onSubmit={handleGetTrainnes}>
            <Form.Control
              type="text"
              className='mb-4'
              placeholder="Pesquise por cpf ou nome do estágiário"
              value={word}
              onChange={(e) => setWord(e.target.value)}
            />
          </Form>
          <ComplexTable
            data={trainees}
            columns={[
              {
                dataField: 'hasContract',
                text: '#',
                sort: true,
                filter: false,
                headerStyle: {
                  width: '5%',
                },
                formatter: (_, row) => (

                  <>
                    {!row.hasContract && <div style={{ height: '20px', borderRadius: '10px', width: '20px', background: 'var(--red-regular)' }}></div>}
                    {(row.hasContract && row.status === false) && <div style={{ height: '20px', borderRadius: '10px', width: '20px', background: '#800080' }}></div>}
                    {(row.hasContract && row.status === true) && <div style={{ height: '20px', borderRadius: '10px', width: '20px', background: 'var(--green-regular)' }}></div>}
                  </>
                )
              },
              {
                dataField: 'name',
                text: 'Nome',
                sort: true,
              },
              {
                dataField: 'cpf',
                text: 'CPF',
                sort: true,
              },
              {
                dataField: 'primary_phone_contact',
                text: '1º Telefone ',
                sort: true,
              },
              {
                dataField: 'email',
                text: 'Email',
                sort: true,
              },
              {
                dataField: 'id',
                text: 'Ações',
                sort: false,
                headerStyle: {
                  width: '35%',
                },
                formatter: (_, row) => (
                  <>
                    <OverlayTrigger
                      key="edit"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          Editar Estagiário
                        </Tooltip>
                      }
                    >
                      <Icon icon="FaEdit" className="mr-4 color-secondary interaction" size={25} onClick={() => history.push(`estagiarios/${row.id}`)} />
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="ocorrencias"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          Ocorrências
                        </Tooltip>
                      }
                    >
                      <Icon icon="FaClipboardList" className="mr-4 color-secondary interaction" size={25} onClick={() => history.push(`observacoes/${row.id}`)} />
                    </OverlayTrigger>


                    <OverlayTrigger
                      key="presencas"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          Presenças
                        </Tooltip>
                      }
                    >
                      <FaFileContract className="mr-4 color-secondary interaction" size={25} onClick={() => history.push(`presencas/${row.id}`, { cpf: row.cpf, name: row.name, contract: row.contract })} />

                    </OverlayTrigger>



                    <OverlayTrigger
                      key="documents"
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          Documentos
                        </Tooltip>
                      }
                    >
                      <Icon icon="FaFolder" className='color-secondary interaction' size={25} onClick={() => history.push(`estagiarios/documentos/${row.id}`, { name: row.name })} />
                    </OverlayTrigger>



                    {row.name.trim() === ('ANULAR') &&

                      <OverlayTrigger

                        key="delete"
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltip-right`}>
                            Deletar Permanentemente
                          </Tooltip>
                        }
                      >
                        <Icon icon="FaTrash" className="ml-4 color-secondary interaction" size={25} onClick={() => handleDelete(row.id)} />
                      </OverlayTrigger>
                    }



                  </>
                )

              },

            ]}
          />

        </Card.Body>
      </Card>
    </Container>
  );
};

export default TraineesList;
