import * as yup from 'yup';

const schema = yup.object().shape({
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .min(14, 'Valor está incompleto'),
  name: yup
    .string()
    .required('Campo obrigatório')
});

export default schema;