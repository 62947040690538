import React from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';


import { ThemeProvider } from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";

import theme from './styles/theme';

import { store, persistor } from './store/index';
import history from './services/history';
import './App.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Footer } from './components/Footer/Footer';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <div style={{minHeight:'100vh'}}>
            <Routes />
            </div>
            <Footer/>
            <ToastContainer autoClose={3000} />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>

  );
}

export default App;