import React from 'react';

import Lottie from 'react-lottie';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';


import animationData from './animation.json'
const Success: React.FC = () => {

 

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Container className="mt-3">
      <Card>
        <h1 style={{textAlign: 'center'}}>DR. Estágio agradece pela sua contribuição !</h1>
        <Lottie options={defaultOptions}
          height={200}
          width={200}
        />

      </Card>
    </Container>
  );
}

export default Success;