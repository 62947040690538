import React, { useState, useEffect } from 'react';
import { Card, Container, ProgressBar, Figure, Spinner } from 'react-bootstrap';
import Bus from '../../../assets/Ilustrations/bus.png';
import Meta from '../../../assets/Images/Meta.png';
import ShineEffect1 from '../../../assets/Images/primary_shine.png';
import ShineEffect2 from '../../../assets/Images/secondary_shine.png';

import rocha750 from '../../../assets/Ilustrations/stage-750.png';
import rocha800 from '../../../assets/Ilustrations/stage-800.png';
import rocha850 from '../../../assets/Ilustrations/stage-850.png';
import rocha900 from '../../../assets/Ilustrations/stage-900.png';
import rocha950 from '../../../assets/Ilustrations/stage-950.png';
import Ferramenta from '../../../assets/Ilustrations/Pickaxe.png';

import Progress1000 from '../../../assets/Ilustrations/progress-1000.svg';
import Progress950 from '../../../assets/Ilustrations/progress-950.svg';
import Progress900 from '../../../assets/Ilustrations/progress-900.svg';
import Progress850 from '../../../assets/Ilustrations/progress-850.svg';
import Progress800 from '../../../assets/Ilustrations/progress-800.svg';
import Progress750 from '../../../assets/Ilustrations/progress-750.svg';

import '../css/ProgressPanel.css';

interface ProgressPanelProps {
  totalContracts: number;
}

const stages = [
  { value: 750, messageH2: "Inspiração", messageH4: "Vamos que vamos!", image: Progress750, rock: rocha750, clicksRequired: 5 },
  { value: 800, messageH2: "Objetivo", messageH4: "Que garra, Parabéns!", image: Progress800, rock: rocha800, clicksRequired: 10 },
  { value: 850, messageH2: "Foco!", messageH4: "Parabéns, Mantenham o Foco!", image: Progress850, rock: rocha850, clicksRequired: 15 },
  { value: 900, messageH2: "Determinação", messageH4: "Que jornada, parabéns! Vamos que falta pouco.", image: Progress900, rock: rocha900, clicksRequired: 20 },
  { value: 950, messageH2: "Visão!", messageH4: "Parabéns, já dar para sentir a brisa da serra!", image: Progress950, rock: rocha950, clicksRequired: 25 },
  { value: 1000, messageH2: "Meta alcançada!!!!", messageH4: "Partiu Viçosa do Ceará", image: Progress1000, rock: rocha750, clicksRequired: 1 },
];

const ProgressPanel: React.FC<ProgressPanelProps> = ({ totalContracts }) => {
  const [brokenRocks, setBrokenRocks] = useState<number[]>(() => {
    const saved = localStorage.getItem('brokenRocks');
    return saved ? JSON.parse(saved) : [];
  });

  const [clickCounts, setClickCounts] = useState<{ [key: number]: number }>({});
  const [busPosition, setBusPosition] = useState(0);
  const [busMargin, setBusMargin] = useState('-150px');
  const [particles, setParticles] = useState<{ id: number, stage: number, direction: string, offsetX: number, offsetY: number }[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showFireworks, setShowFireworks] = useState(false);
  const [displayedContracts, setDisplayedContracts] = useState(0);
  const [cooldown, setCooldown] = useState(false);  // Cooldown state

  useEffect(() => {
    localStorage.setItem('brokenRocks', JSON.stringify(brokenRocks));
  }, [brokenRocks]);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(loadingTimer);
  }, []);

  useEffect(() => {
    if (totalContracts >= 1000) {
      setShowFireworks(true);
      const allStages = stages.map(stage => stage.value);
      setBrokenRocks(allStages);
    }
  }, [totalContracts]);

  useEffect(() => {
    let start = displayedContracts;
    const end = totalContracts;
    const duration = 5000;
    const increment = (end - start) / (duration / 50);

    const animateCount = () => {
      start += increment;
      if (start < end) {
        setDisplayedContracts(Math.floor(start));
        setTimeout(animateCount, 50);
      } else {
        setDisplayedContracts(end);
      }
    };

    animateCount();
  }, [totalContracts]);

  const generateParticles = (stageValue: number) => {
    const directions = ['left', 'right', 'up-left', 'up-right'];
    const newParticles = directions.map((direction, index) => ({
      id: Date.now() + index,
      stage: stageValue,
      direction,
      offsetX: Math.random() * 40 - 20,
      offsetY: Math.random() * 40 - 20,
    }));
    setParticles((prev) => [...prev, ...newParticles]);

    setTimeout(() => {
      setParticles((prev) => prev.filter(p => !newParticles.some(np => np.id === p.id)));
    }, 3000);
  };

  const handleClick = (stageValue: number) => {
    if (cooldown || brokenRocks.includes(stageValue)) return;

    setCooldown(true);
    setTimeout(() => setCooldown(false), 500);

    const stage = stages.find(s => s.value === stageValue);
    const requiredClicks = stage?.clicksRequired || 10;

    setClickCounts(prev => {
      const newCount = (prev[stageValue] || 0) + 1;
      if (newCount >= requiredClicks) {
        setBrokenRocks(prevRocks => [...prevRocks, stageValue]);
        moveBusToStage(stageValue);
        if (stageValue === 800) {
          setBusMargin('-75px');
        }

        // Adicionar efeito de explosão
        const rockContainer = document.querySelector(`.rock-container[data-stage="${stageValue}"]`);
        if (rockContainer) {
          const explosionElement = document.createElement('div');
          explosionElement.className = 'explosion-effect';
          rockContainer.appendChild(explosionElement);
          setTimeout(() => rockContainer.removeChild(explosionElement), 500);  // Remover após a animação
        }
      }
      generateParticles(stageValue);

      // Aplicar efeito de tremer
      const rockElement = document.querySelector(`.rock-container[data-stage="${stageValue}"] .rock`);
      if (rockElement) {
        rockElement.classList.add('shake');
        setTimeout(() => {
          rockElement.classList.remove('shake');
        }, 500);
      }

      return { ...prev, [stageValue]: newCount };
    });
  };

  const moveBusToStage = (stageValue: number) => {
    const position = (stageValue - 750) / 2.5;
    setBusPosition(position);

    const busElement = document.querySelector('.progress-bus');
    if (busElement) {
      busElement.classList.add('moving');
      setTimeout(() => busElement.classList.remove('moving'), 1000);  // Remover após a animação
    }
  };

  const adjustedContracts = totalContracts >= 750 ? totalContracts - 750 : 0;
  const percentage = Math.min(100, (adjustedContracts / 250) * 100);

  const currentStage = stages.slice().reverse().find(stage => totalContracts >= stage.value) || stages[0];

  useEffect(() => {
    if (brokenRocks.length === 0) {
      setBusPosition(0);
    } else {
      const lastBrokenStage = Math.max(...brokenRocks);
      moveBusToStage(lastBrokenStage);
    }
  }, [brokenRocks]);

  return (
    <Container>
      {showFireworks && (
        <>
          <div className="celebrate-message">Parabéns! Meta Alcançada!</div>
          <div className="confetti-container">
            {[...Array(100)].map((_, index) => (
              <div key={index} className="confetti" style={{
                left: `${Math.random() * 100}%`,
                animationDuration: `${Math.random() * 3 + 2}s`,
                animationDelay: `${Math.random() * 2}s`,
              }} />
            ))}
            {[...Array(10)].map((_, index) => (
              <div key={index} className="firework" style={{
                top: `${Math.random() * 50}%`,
                left: `${Math.random() * 100}%`,
                animationDuration: `${Math.random() * 1.5 + 1}s`,
              }} />
            ))}
          </div>
        </>
      )}
      <Card className='progress-content aling-items-end justify-content-end' style={{ minHeight: '450px' }}>
        <div className='progress-menssage'>
          <Figure className='w-100 d-flex justify-center flex-column'>
            {isLoading ? (
              <Spinner animation="border" variant="primary" style={{ height: '233px', width: '233px', margin: '0 auto' }} role="status" />
            ) : (
              currentStage && (
                <>
                  <Figure.Image
                    width={250}
                    className='m-0 m-auto'
                    src={currentStage.image}
                  />
                </>
              )
            )}
          </Figure>
        </div>
        <div className='progress-area position-relative'>
          <div className='progress-bus' style={{ left: `${busPosition}%`, marginLeft: busMargin }}>
            <img width='150px' src={Bus} alt='Ônibus' />
          </div>
          <div className='progress-meta-png'>
            <img src={Meta} width='120px' className='position-relative top-0' style={{ zIndex: 3 }} alt="meta" />
            <img src={ShineEffect1} style={{ zIndex: 2 }} className='progress-meta-effect' width='200px' alt='effect' />
            <img src={ShineEffect2} style={{ zIndex: 1 }} className='progress-meta-effect2' width='200px' alt='effect' />
          </div>
          <div className='progress-rocks'>
            {stages.map((stage, index) => {
              const previousStageValue = stages[index - 1]?.value;
              const canBreak = previousStageValue === undefined || brokenRocks.includes(previousStageValue);

              return (
                <div
                  key={stage.value}
                  className={`rock-container ${brokenRocks.includes(stage.value) ? 'broken' : ''}`}
                  data-stage={stage.value}
                  style={{ left: `${(stage.value - 750) / 2.5}%` }}
                >
                  {canBreak && !brokenRocks.includes(stage.value) && totalContracts >= stage.value && (
                    <img
                      src={Ferramenta}
                      alt="Picareta"
                      className="pickaxe"
                      onClick={() => handleClick(stage.value)}
                    />
                  )}
                  <img src={stage.rock} alt={`Rocha ${stage.value}`} className={`rock`} />
                  {particles
                    .filter(p => p.stage === stage.value)
                    .map(p => (
                      <div key={p.id} className={`particle ${p.direction}`} style={{ transform: `translate(${p.offsetX}px, ${p.offsetY}px)` }} />
                    ))}
                </div>
              );
            })}
          </div>
          <ProgressBar className='bg-black' animated now={percentage} />
          <div className='progress-meta'>
            <p>{displayedContracts}</p> / <b style={{ color: 'var(--secondary-strong)' }}> 1000</b>
          </div>
        </div>
      </Card>

      <div className='text-center progress-text' style={{ background: 'linear-gradient(90deg, rgb(0 10 255) 0%, rgb(71 35 142) 100%)', padding: '1rem', borderRadius: '10px', marginTop: '1rem', marginBottom:'1rem' }}>
        <h2 style={{ color: 'white' }}>{currentStage.messageH2}</h2>
        <h4 style={{ color: 'white' }}>{currentStage.messageH4}</h4>
      </div>
    </Container>
  );
};

export default ProgressPanel;
