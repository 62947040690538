import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers';

// Importações do react-bootrap
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import swal from 'sweetalert'
import axios from 'axios'

// Componentes da aplicação
import MaskedInput from '../../components/InputMask';
import Input from '../../components/Input';
import Select from '../../components/Select';

import theme from 'src/styles/theme';
import schema from './schema'

import InputMask from '../../components/InputMask';

import { differenceInYears } from 'date-fns';
import Swal from 'sweetalert2';


const genderOptions = [
  {
    value: 'M',
    label: 'Masculino'
  },
  {
    value: 'F',
    label: 'Feminino'
  }
];

const ConvitesForm: React.FC = () => {
  const history = useHistory();

  const params = useParams()
  const [loading, setLoading] = useState<boolean>(false);

  const { handleSubmit, control, watch, setValue, errors } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      cpf: '',
      name: '',
      rg: '',
      primary_phone_contact: '',
      secondary_phone_contact: '',
      gender: '',
      date_birth: undefined,
      cep: '',
      address: '',
      neighborhood: '',
      city: '',
      state: '',
      number_home: '',
      complement: '',
      father_name: '',
      mother_name: '',
      have_special_needs: {
        value: false,
        label: 'Não é portador de necessidades especiais'
      },
      email: '',
      document_responsible: ''
    }
  });

  const watchCep = watch('cep');

  useEffect(() => {
    if (watchCep.length === 9) {
      swal({
        title: "Buscar CEP automaticamente ?",
        icon: "warning",
        buttons: ["Cancelar", true],
      })
        .then((value) => {
          if (value) {
            axios.get(`https://viacep.com.br/ws/${watchCep}/json/`)
              .then(response => {
                setValue('neighborhood', response.data?.bairro);
                setValue('address', response.data?.logradouro);
                setValue('city', response.data?.localidade);
                setValue('state', response.data?.uf);
                setValue('complement', response.data?.complemento);
              })
          }
        });
    }
  }, [watchCep, setValue]);

  const onSubmit = async data => {

    setLoading(true)
    const splitDate = data.date_birth.split("/");

    const newDate = new Date(Number(splitDate[2]), Number(splitDate[1]) - 1, Number(splitDate[0]));

    const age = Number(`${differenceInYears(new Date(), newDate)}`);

    history.push('/convites/documentos', {
      trainee: {
        cpf: data.cpf,
        name: data.name,
        rg: data.rg,
        primary_phone_contact: data.primary_phone_contact,
        secondary_phone_contact: data.secondary_phone_contact,
        gender: data.gender?.value,
        date_birth: newDate,
        cep: data.cep,
        address: data.address,
        neighborhood: data.neighborhood,
        city: data.city,
        state: data.state,
        number: data.number_home,
        complement: data.complement,
        father_name: data.father_name,
        mother_name: data.mother_name,
        have_special_needs: data.have_special_needs?.value,
        email: data.email,
        application_id: params.inviteApplication,
        document_responsible: data.document_responsible
      }, age
    });
  };

  useEffect(() => {

    Swal.fire({
      icon: 'info',
      title: 'Esse site armazena dados',
      html: "Nós guardamos os seus dados para manutenção de contratos gerados na plataforma. Ao utilizar os nossos serviços, você declara o seu EXPRESSO CONSENTIMENTO para coletarmos, tratarmos e armazenarmos dados sobre você quando julgarmos necessários."
    })

  }, [])

  return (
    <>
      <Container className="mt-3">
        <Card style={{ background: 'var(--gray-100)', borderRadius: '10px',boxShadow:'var(--card-bs)' }}>
          <Card.Header
            as='h5'
            style={{
              color: 'var(--secondary-strong)',
              background: 'transparent',
              border: 'none'
            }}
          >
            Ficha de cadastro
          </Card.Header>


          <Form onSubmit={handleSubmit(onSubmit)}>
            <Card.Body 
            style={{margin:'0.5rem 1rem', background:'white', borderRadius:'10px'}}>
              <p>
                Todos os campos com <b className='text-danger'>*</b> são obrigatórios
              </p>
              <Form.Row>
                <Controller
                  as={MaskedInput}
                  control={control}
                  name="cpf"
                  mask="cpf"
                  label="CPF"
                  errorMessage={errors.cpf?.message}
                  md="3"
                  required
                />

                <Controller
                  as={Input}
                  control={control}
                  name="name"
                  label="Nome completo"
                  errorMessage={errors.name?.message}
                  md="4"
                  required
                />
                <Controller
                  as={Input}
                  control={control}
                  name="rg"
                  label="RG"
                  md="5"
                  errorMessage={errors.rg?.message}
                  required
                />
              </Form.Row>
              <Form.Row>

                <Controller
                  as={MaskedInput}
                  control={control}
                  name="primary_phone_contact"
                  mask="cel"
                  label="Contato principal - whatsapp"
                  md="3"
                  errorMessage={errors.primary_phone_contact?.message}
                  required
                />
                <Controller
                  as={MaskedInput}
                  control={control}
                  name="secondary_phone_contact"
                  mask="cel"
                  label="Contato Secundário - whatsapp "
                  md="3"
                />
                <Controller
                  as={Select}
                  control={control}
                  name="gender"
                  label="Sexo"
                  options={genderOptions}
                  md="3"
                  errorMessage={errors.gender?.message}
                  required
                />
                <Controller
                  as={InputMask}
                  control={control}
                  name="date_birth"
                  mask="date"
                  label="Data de nascimento"
                  md="3"
                  errorMessage={errors.date_birth?.message}
                  required
                />

              </Form.Row>
              <hr />
              <Form.Row>
                <Controller
                  as={MaskedInput}
                  control={control}
                  name="cep"
                  mask="cep"
                  label="CEP"
                  md="3"
                  errorMessage={errors.cep?.message}
                  required
                />
                <Controller
                  as={Input}
                  control={control}
                  name="address"
                  label="Endereço"
                  md="3"
                  errorMessage={errors.address?.message}
                  required
                />
                <Controller
                  as={Input}
                  control={control}
                  name="neighborhood"
                  label="Bairro"
                  md="3"
                  errorMessage={errors.neighborhood?.message}
                  required
                />
                <Controller
                  as={Input}
                  control={control}
                  name="city"
                  label="Cidade"
                  md="3"
                  errorMessage={errors.city?.message}
                  required

                />
              </Form.Row>
              <Form.Row>
                <Controller
                  as={Input}
                  control={control}
                  name="state"
                  label="Estado"
                  md="3"
                  errorMessage={errors.state?.message}
                  required
                />
                <Controller
                  as={Input}
                  control={control}
                  name="number_home"
                  label="Número casa/apartamento"
                  md="3"
                  errorMessage={errors.number_home?.message}
                  required

                />
                <Controller
                  as={Input}
                  control={control}
                  name="complement"
                  label="Complemento"
                  md="6"
                  errorMessage={errors.complement?.message}
                />
              </Form.Row>
              <hr />
              <Form.Row>

                <Controller
                  as={Input}
                  control={control}
                  name="father_name"
                  label="Nome do responsável"
                  errorMessage={errors.father_name?.message}
                  required
                  md="6"
                />
                <Controller
                  as={Input}
                  control={control}
                  name="mother_name"
                  label="Telefone responsável"
                  md="3"
                  errorMessage={errors.mother_name?.message}
                />
                <Controller
                  as={MaskedInput}
                  mask="cpf"
                  control={control}
                  name="document_responsible"
                  label="CPF do responsável"
                  md="3"
                />
              </Form.Row>
              <hr />
              <Form.Row>

                <Controller
                  as={Select}
                  control={control}
                  name="have_special_needs"
                  label="PNE - (Portador de necessidades especiais)"
                  options={[
                    {
                      value: true,
                      label: 'Sim'
                    },
                    {
                      value: false,
                      label: 'Não'
                    }
                  ]}
                  isClearable={false}
                  md="6"
                />

                <Controller
                  as={Input}
                  control={control}
                  name="email"
                  label="Email"
                  errorMessage={errors.email?.message}
                  required
                  md="6"
                />
              </Form.Row>

            </Card.Body>
            <Card.Footer className='bg-white'>
              {!loading ? (
                <Form.Row>
                  <Button
                    className="mr-3 button-primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? 'Salvando ...' : 'Salvar'}
                  </Button>
                </Form.Row>
              ) : 'Salvando'}


            </Card.Footer>
          </Form>
        </Card>
      </Container>

    </>
  );
}

export default ConvitesForm;
