import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

// Importações do bootstrap
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import swal from 'sweetalert'
import axios from 'axios'

import MaskedInput from '../../../components/InputMask';
import Input from '../../../components/Input';

import api from 'src/services/axios';

import { toast } from 'react-toastify';
import schema from './schema';
import { yupResolver } from '@hookform/resolvers';

const InstituicaoEducacaoForm: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();

  const history = useHistory();

  const { handleSubmit, control, watch, setValue, errors } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      cnpj: '',
      name: '',
      contact: '',
      cep: '',
      address: '',
      neighborhood: '',
      city: '',
      state: '',
      number: '',
      complement: '',
    }
  });


  const watchCep = watch('cep', '');

  useEffect(() => {
    if (watchCep.length === 9 && !params.id) {
      swal({
        title: "Buscar CEP automaticamente ?",
        icon: "warning",
        buttons: ["Cancelar", true],
      })
        .then((value) => {
          if (value) {
            axios.get(`https://viacep.com.br/ws/${watchCep}/json/`)
              .then(response => {
                setValue('neighborhood', response.data?.bairro);
                setValue('address', response.data?.logradouro);
                setValue('city', response.data?.localidade);
                setValue('state', response.data?.uf);
                setValue('complement', response.data?.complemento);
              })
          }
        });
    }
  }, [watchCep, setValue, params.id]);


  useEffect(() => {
    if (params.id) {
      api.get(`/educational_institutions/${params.id}`).then(response => {
        setValue('cnpj', response.data.cnpj);
        setValue('name', response.data.name);
        setValue('contact', response.data.contact);
        setValue('cep', response.data.cep || '');
        setValue('address', response.data.address);
        setValue('neighborhood', response.data.neighborhood);
        setValue('state', response.data.state);
        setValue('city', response.data.city);
        setValue('number', response.data.number);
        setValue('complement', response.data.complement);
      })
    }
  }, [params.id, setValue]);

  const onSubmit = data => {
    setLoading(true)
    const body = {
      cnpj: data.cnpj,
      name: data.name,
      contact: data.contact,
      cep: data.cep,
      address: data.address,
      neighborhood: data.neighborhood,
      city: data.city,
      state: data.state,
      number: data.number,
      complement: data.complement,
    };


    if (params.id) {
      api.put(`/educational_institutions/${params.id}`, body).then(() => {
        history.goBack();
        toast.success("Atualizado com sucesso!");
      }).catch(() => {
        toast.error("Houve um erro ao atualizar a instituição de educação!");
      })
    } else {
      api.post('/educational_institutions', body).then(() => {
        history.goBack();
        toast.success("Cadastrado com sucesso!");
      }).catch(() => {
        toast.error("Houve um erro ao cadastrar a instituição de educação!");
      })
    }
  };


  return (
    <Container>
      <Card style={{background:'var(--gray-100)',boxShadow:'var(--card-bs)',overflow:'hidden'}}>
        <Card.Header style={{background:'transparent', border:'none',color:'var(--secondary-color)'}} as='h5'>
          Nova instituição de educação
        </Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body style={{margin:'0.5rem 1rem', background:'white',borderRadius:'10px'}}>
            <Form.Row>
              <Controller
                as={MaskedInput}
                control={control}
                name="cnpj"
                mask="cnpj"
                label="CNPJ"
                errorMessage={errors.cnpj?.message}
                md="3"
                required
              />
              <Controller
                as={Input}
                control={control}
                name="name"
                label="Razão Social"
                errorMessage={errors.name?.message}
                md="3"
                required
              />
              <Controller
                as={MaskedInput}
                mask="tel"
                control={control}
                name="contact"
                label="Telefone da empresa"
                md="3"
                required
                errorMessage={errors.contact?.message}
              />
            </Form.Row>
            <hr />
            <Form.Row>
              <Controller
                as={MaskedInput}
                control={control}
                name="cep"
                mask="cep"
                label="CEP"
                md="3"
                required
                errorMessage={errors.cep?.message}
              />
              <Controller
                as={Input}
                control={control}
                name="address"
                label="Endereço"
                md="3"
                required
                errorMessage={errors.address?.message}
              />
              <Controller
                as={Input}
                control={control}
                name="neighborhood"
                label="Bairro"
                md="3"
                required
                errorMessage={errors.neighborhood?.message}
              />
              <Controller
                as={Input}
                control={control}
                name="city"
                label="Cidade"
                md="3"
                required
                errorMessage={errors.city?.message}
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Input}
                control={control}
                name="state"
                label="Estado"
                md="3"
                required
                errorMessage={errors.state?.message}
              />
              <Controller
                as={Input}
                control={control}
                name="number"
                label="Número"
                md="3"
                required
                errorMessage={errors.number?.message}

              />
              <Controller
                as={Input}
                control={control}
                name="complement"
                label="Complemento"
                md="6"
              />
            </Form.Row>
          </Card.Body>
          <Card.Footer className='bg-white'>
            <Form.Row>
              <Button className="mr-3 button-primary" type="submit" disabled={loading} variant="success">{loading ? 'Carregando...' : 'Salvar'}</Button>
              <Button className='button-secondary-outline' onClick={() => history.goBack()} >Cancelar</Button>
            </Form.Row>
          </Card.Footer>
        </Form>
      </Card>
    </Container>
  );
}

export default InstituicaoEducacaoForm;